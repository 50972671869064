import React, { useEffect, useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

function Header() {
    const [theme, setTheme] = useState(sessionStorage.getItem('theme') || 'light');
    const searchRef = useRef();
    useEffect(() => {
        sessionStorage.setItem('theme', theme);
        if (theme === "light") {
            document.querySelector('html').classList.remove('dark-style');
            document.querySelector('html').classList.add('light-style');
            let ele = document.querySelector('#themestyle');
            let link = ele.getAttribute('href');
            link = link.replace('-dark', '');
            ele.setAttribute('href', link);

            let ele2 = document.querySelector('#themestyle1');
            let link2 = ele2.getAttribute('href');
            link2 = link2.replace('-dark', '');
            ele2.setAttribute('href', link2);
        } else {
            document.querySelector('html').classList.add('dark-style');
            document.querySelector('html').classList.remove('light-style');
            let ele = document.querySelector('#themestyle');
            let link = ele.getAttribute('href');
            if (!link.includes('-dark')) {
                link = link.replace('.css', '-dark.css');
                ele.setAttribute('href', link);
            }

            let ele2 = document.querySelector('#themestyle1');
            let link2 = ele2.getAttribute('href');
            if (!link2.includes('-dark')) {
                link2 = link2.replace('.css', '-dark.css');
                ele2.setAttribute('href', link2);
            }
        }
    }, [theme])
    const navigate = useNavigate();
    const toggleSearch = () => {
        document.querySelector(".search-input-wrapper").classList.toggle("d-none");
        if (searchRef && searchRef.current)
            searchRef.current.focus();
    }
    return (
        <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0   d-xl-none ">
                <label className="nav-item nav-link px-0 me-xl-4" onClick={() => {
                    document.querySelector('html').classList.toggle("layout-menu-collapsed");
                    if (window.innerWidth < 720) {
                        document.querySelector('html').classList.toggle("layout-menu-expanded")
                    }
                }}>
                    <i className="ti ti-menu-2 ti-sm"></i>
                </label>
            </div>
            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                {/* <div className="navbar-nav align-items-center">
                    <div className="nav-item navbar-search-wrapper mb-0">
                        <label className="nav-item nav-link search-toggler d-flex align-items-center px-0" onClick={toggleSearch}>
                            <i class="ti ti-search ti-md me-2"></i>
                            <span class="d-none d-md-inline-block text-muted">Search...</span>
                        </label>
                    </div>
                </div> */}

                <ul className="navbar-nav flex-row align-items-center ms-auto">
                    {/* <li className="nav-item dropdown-language dropdown me-2 me-xl-0">
                        <label className="nav-link dropdown-toggle hide-arrow">
                            <i className="ti ti-copy rounded-circle ti-md"></i>
                        </label>
                    </li> */}
                    <li class="nav-item dropdown-style-switcher dropdown me-2 me-xl-0">
                        <label class="nav-link dropdown-toggle hide-arrow" onClick={() => {
                            setTheme(theme === "light" ? "dark" : "light");
                        }}>
                            <i class={`ti ti-md ti-${theme === "light" ? "moon" : "sun"}`}></i>
                        </label>
                    </li>
                    <li className="nav-item dropdown-language dropdown me-2 me-xl-0">
                        <label className="nav-link dropdown-toggle hide-arrow" onClick={(e) => {
                            localStorage.clear();
                            navigate('/');
                        }}>
                            <i className='ti ti-logout rounded-circle ti-md'></i>
                        </label>
                    </li>
                    <Dropdown className="nav-item navbar-dropdown dropdown-user dropdown" as={"li"}>
                        <Dropdown.Toggle as={"a"} className='nav-link dropdown-toggle hide-arrow' variant="" id="dropdown-basic">
                            <div className="avatar avatar-online">
                                <img src={require('../Assets/Images/1.png')} alt className="h-auto rounded-circle" />
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='dropdown-menu dropdown-menu-end'>
                            <Dropdown.Item as={Link} to={"/profile"}>
                                <div className="d-flex">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar avatar-online">
                                            <img src={require('../Assets/Images/1.png')} alt className="h-auto rounded-circle" />
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <span className="fw-medium d-block">{localStorage.getItem('username')}</span>
                                        <small className="text-muted">{localStorage.getItem('fullname')}</small>
                                    </div>
                                </div>
                            </Dropdown.Item>
                            {/* <Dropdown.Item as={Link} to={"/profile"}>
                                <i className="ti ti-user-check me-2 ti-sm"></i>
                                <span className="align-middle">My Profile</span>
                            </Dropdown.Item> */}
                            <Dropdown.Item as={Link} to={"/settings"}><i className="ti ti-settings me-2 ti-sm"></i>
                                <span className="align-middle">Settings</span></Dropdown.Item>
                            {/* <Dropdown.Item>
                                <div className="dropdown-divider"></div>
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to={"/investors"}><i className="ti ti-triangle-inverted me-2 ti-sm"></i>
                                <span className="align-middle">Investors</span></Dropdown.Item>
                            <Dropdown.Item as={Link} to={"/investments"}><i className="ti ti-wallet me-2 ti-sm"></i>
                                <span className="align-middle">Investments</span></Dropdown.Item>
                            <Dropdown.Item as={Link} to={"/emergency"}><i className="ti ti-urgent me-2 ti-sm"></i>
                                <span className="align-middle">Emergency</span></Dropdown.Item>
                            <Dropdown.Item as={Link} to={"/currency"}><i className="ti ti-currency me-2 ti-sm"></i>
                                <span className="align-middle">Currency</span></Dropdown.Item>
                            <Dropdown.Item as={Link} to={"/restrict"}><i className="ti ti-ban me-2 ti-sm"></i>
                                <span className="align-middle">Restrict</span></Dropdown.Item>
                            <Dropdown.Item>
                                <div className="dropdown-divider"></div>
                            </Dropdown.Item> */}
                            <Dropdown.Item onClick={() => {
                                localStorage.clear();
                                navigate("/");
                            }}>
                                <i className="ti ti-logout me-2 ti-sm"></i>
                                <span className="align-middle">Log Out</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </ul>
            </div>
            <div className="navbar-search-wrapper search-input-wrapper  d-none">
                <input onBlur={() => {
                    toggleSearch();
                }} type="text" ref={searchRef} className="form-control search-input container-xxl border-0" placeholder="Search..." />
                <i className="ti ti-x ti-sm search-toggler cursor-pointer" onClick={toggleSearch}></i>
            </div>
        </nav>
    )
}

export default Header