import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { errorToast, successToast } from '../Common/showtoast';
import ApiRequest from '../Common/restservice';

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [ispassword, setisPassword] = useState(false);
    const navigate = useNavigate();
    const theme = sessionStorage.getItem('theme') || 'light';
    useEffect(() => {
        sessionStorage.setItem('theme', theme);
        if (theme === "light") {
            document.querySelector('html').classList.remove('dark-style');
            document.querySelector('html').classList.add('light-style');
            let ele = document.querySelector('#themestyle');
            let link = ele.getAttribute('href');
            link = link.replace('-dark', '');
            ele.setAttribute('href', link);

            let ele2 = document.querySelector('#themestyle1');
            let link2 = ele2.getAttribute('href');
            link2 = link2.replace('-dark', '');
            ele2.setAttribute('href', link2);
        } else {
            document.querySelector('html').classList.add('dark-style');
            document.querySelector('html').classList.remove('light-style');
            let ele = document.querySelector('#themestyle');
            let link = ele.getAttribute('href');
            if (!link.includes('-dark')) {
                link = link.replace('.css', '-dark.css');
                ele.setAttribute('href', link);
            }

            let ele2 = document.querySelector('#themestyle1');
            let link2 = ele2.getAttribute('href');
            if (!link2.includes('-dark')) {
                link2 = link2.replace('.css', '-dark.css');
                ele2.setAttribute('href', link2);
            }
        }
    }, [theme])
    return (
        <div class="container-xxl">
            <div class="authentication-wrapper authentication-basic container-p-y">
                <div class="authentication-inner py-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="app-brand justify-content-center mb-4 mt-2">
                                <a href="index.html" class="app-brand-link gap-2">
                                    <span class="app-brand-logo demo">
                                        <img src={require('../Assets/Images/logo-dark.png')} alt='' style={{ width: 30, height: 30 }} />
                                    </span>
                                    <div className="app-brand-text demo menu-text fw-bold">
                                        <h4 className='mb-0'>DEDICO</h4>
                                        <h4 className='mb-0' style={{ fontSize: 8 }}>NETWORK PRIVATE LIMITED</h4>
                                    </div>
                                </a>
                            </div>
                            <h4 class="mb-1 pt-2">Welcome to Dedico! 👋</h4>
                            <p class="mb-4">Please sign-in to your account and start the adventure</p>

                            <form id="formAuthentication" class="mb-3" onSubmit={async (e) => {
                                e.preventDefault();
                                if (!username) {
                                    errorToast("Enter valid username");
                                    return;
                                } else if (!password) {
                                    errorToast("Enter valid password");
                                    return;
                                }
                                let jsoninput = {
                                    username: username,
                                    password: password
                                }
                                let res = await ApiRequest("login.php", "POST", JSON.stringify(jsoninput));
                                if (res.status === "error") {
                                    errorToast(res.message);
                                    return;
                                }
                                successToast(res.message);                                
                                localStorage.setItem("username", res.name);                        
                                localStorage.setItem("fullname", res.investorname);
                                localStorage.setItem("token", res.token);
                                localStorage.setItem("permission_status", res.permission_status);
                                navigate("/home");
                            }}>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Email or Username</label>
                                    <input type="text" name="username" id="username" className="form-control" placeholder="Enter your account number" onChange={(e) => {
                                        setUsername(e.target.value);
                                    }} value={username} />
                                </div>
                                <div class="mb-3 form-password-toggle">
                                    <div class="d-flex justify-content-between">
                                        <label class="form-label" for="password">Password</label>
                                        {/* <Link to={"/forgotpassword"}>
                                            <small>Forgot Password?</small>
                                        </Link> */}
                                    </div>
                                    <div class="input-group input-group-merge">
                                        <input type={!ispassword ? "password" : "text"} name="password" id="password" className="form-control" placeholder="Enter your password" onChange={(e) => {
                                            setPassword(e.target.value)
                                        }} value={password} />
                                        <span class="input-group-text cursor-pointer" onClick={()=>{
                                            setisPassword(!ispassword);
                                        }}><i class={`ti ti-eye${ispassword ? "-off" : ""}`}></i></span>
                                    </div>
                                </div>
                                {/* <div class="mb-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="remember-me" />
                                        <label class="form-check-label" for="remember-me">
                                            Remember Me
                                        </label>
                                    </div>
                                </div> */}
                                <div class="mb-3">
                                    <button class="btn btn-success d-grid w-100" type="submit">Sign in</button>
                                </div>
                            </form>

                            {/* <p class="text-center">
                                <span>New on our platform?</span>
                                <Link to={"/register"}>
                                    <span> Create an account</span>
                                </Link>
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login