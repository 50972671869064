import React from 'react'

function Footer() {
    return (
        <footer className="content-footer footer bg-footer-theme">
            <div className="container-xxl">
                <div className="justify-content-center footer-container d-flex py-2 flex-md-row flex-column">
                    <div className='text-center'>
                        © {new Date().getFullYear()}, All rights reserved by <a href="#" target="_blank" className="footer-link text-success fw-medium">DEDICO</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer