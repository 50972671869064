import React, { useEffect, useState } from 'react'
import ApiRequest from '../../Common/restservice';
import { useNavigate } from 'react-router-dom';
import { errorToast } from '../../Common/showtoast';
import DataTable from '../../Components/DataTable';
import { toast } from 'react-toastify';
import moment from 'moment';
import Swal from 'sweetalert2';

function InvestorsList() {
    const [tableData, setTableData] = useState([{ id: "INV001", headstatus: "true", investor: "Kani Anna", head: "Dharsan", date: "12/07/2023", country: "Switzerlad" }]);
    const [fromDate, setFromDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().add(1, 'days').format('YYYY-MM-DD'));
    const navigate = useNavigate();
    useEffect(() => {
        GetTeamList();
    }, [])
    const GetTeamList = async () => {
        let jsoninput = {
            from_date: fromDate ? moment(fromDate).format("YYYY/MM/DD") : "",
            to_date: toDate ? moment(toDate).format("YYYY/MM/DD") : ""
        }
        let url = "investor_report.php";
        let res = await ApiRequest(url, "POST", JSON.stringify(jsoninput), { Authorization: localStorage.getItem("token") });
        if (res.status === "error") {
            if (res.message === "Session Expired" || res.message === "Token expired or invalid") {
                localStorage.clear();
                navigate("/login");
            }
            setTableData([]);
            return;
        }
        setTableData(res.items);
    }
    const DeleteorBlockInvestor = async (id,isblock) => {
        let url = isblock ? "investor_status.php" : "delete_investor.php";
        let jsoninput = {id};
        if(isblock){
            jsoninput.status = isblock === "1" ? "0" : "1";
        }
        let res = await ApiRequest(url, "POST", JSON.stringify(jsoninput), { Authorization: localStorage.getItem("token") });
        if (res.status === "error") {
            errorToast(res.message);
            if (res.message === "Session Expired" || res.message === "Token expired or invalid") {
                localStorage.clear();
                navigate("/login");
            }
            setTableData([]);
            return;
        }
        GetTeamList();
    }
    return (
        <div className='container-xxl flex-grow-1 container-p-y'>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className='card mb-2'>
                        <div className="card-header">
                            <h4 className="card-title text-color-1 mb-0">
                                Search Investors
                            </h4>
                        </div>
                        <div className='card-body'>
                            <div className="d-md-flex mb-3" style={{ justifyContent: "space-evenly", alignItems: 'end' }}>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label className='form-label'>From Date</label>
                                        <div className="input-group">

                                            <input type="date" className="form-control daterange-single" id="start_date" name="start_date" placeholder="From Date" autoComplete="off" onChange={(e) => {
                                                setFromDate(e.target.value);
                                            }} value={fromDate} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label className='form-label'>To Date</label>
                                        <div className="input-group">
                                            <input type="date" className="form-control daterange-single" id="end_date" name="end_date" placeholder="To Date" autoComplete="off" onChange={(e) => {
                                                setToDate(e.target.value);
                                            }} value={toDate} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 text-left">
                                    <div className="form-group"><button type="button" className="btn btn-success w-100" onClick={(e) => { GetTeamList() }}> Search </button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        title={"Investors"}
                        heads={[{
                            name: "Investor",
                            fieldname: "investor",
                            view: (item) => <div>
                                <h5 className='mb-1'>{item.name}</h5>
                                <label style={{ fontSize: '12px' }}>{item.memberid}</label>
                            </div>
                        }, 
                        // {
                        //     name: "Head",
                        //     fieldname: "head",
                        //     //TODO update head,headstatus field name accordingly
                        //     view: (item) => <div>
                        //         <h6 className='mb-1'>{item.head}</h6>
                        //         <label style={{ fontSize: '12px' }} className={`badge ${item.headstatus === "true" ? "bg-success" : "bg-danger"}`}>{item.headstatus === "true" ? "True" : "False"}</label>
                        //     </div>
                        // }, 
                        {
                            name: "Currency",
                            fieldname: "currency"
                        }, {
                            name: "Country",
                            fieldname: "country"
                        },
                        {
                            name: "Joined On",
                            fieldname: "cdate",
                            view:(item)=><label>{item.cdate && moment(item.cdate).format('DD/MM/YYYY')}</label>
                        },{
                            name:"Status",
                            view:(item)=><span title={item.status === "1" ? "Block" : "Unblock"} className={`badge ${item.status !== "1" ? "bg-danger" : "bg-success"}`} 
                            >{item.status === "1" ? "Active" : "Inactive"}</span>
                        }, 
                        // {
                        //     name: "Task",
                        //     view: (item) => <span className='d-flex' style={{ justifyContent: 'space-evenly' }}>
                        //         <span className='badge bg-info' style={{cursor:'pointer'}} onClick={(e) => {
                        //             navigate('/editinvestor/'+item.id);
                        //         }}><i className='ti ti-pencil' /></span>
                        //         <span className='badge bg-danger' style={{cursor:'pointer'}} onClick={async (e) => {
                        //             let res = await Swal.fire({
                        //                 title:"Delete",
                        //                 text:"Are you sure you want to delete this record?",
                        //                 confirmButtonText:"Yes",
                        //                 cancelButtonText:"No",
                        //                 showCancelButton:true
                        //             })
                        //             if(res.isConfirmed)
                        //                 DeleteorBlockInvestor(item.id)
                        //         }}><i className='ti ti-trash' /></span>
                                
                        //     </span>
                        // }
                    ]}
                        data={tableData} />
                </div>
            </div>
        </div>
    )
}

export default InvestorsList
