import React, { useEffect, useState } from 'react'
// import PageHeader from '../components/pageheader'
import { useNavigate } from 'react-router-dom';
import ApiRequest from '../Common/restservice';
import DataTable from '../Components/DataTable';
import { GetCountries } from 'react-country-state-city';

function Dashboard() {
    const [headOfInvesters, setHeadOfInvesters] = useState([]);
    const [overAllAmount, setoverAllAmount] = useState([]);
    const [overAllCurrentAmount, setoverAllCurrentAmount] = useState([]);
    const [currentExchangeRate, setcurrentExchangeRate] = useState([]);
    const [top10Investors, settop10Investors] = useState([]);
    const [countries, setCountries] = useState([]);
    const [stateObj, setstateObj] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
        GetHeadOfInvestors();
    }, [])
    const GetHeadOfInvestors = async () => {
        let resdash = await ApiRequest("get_dashboard.php", "GET", null, {
            Authorization: localStorage.getItem("token"),
          });
          if (resdash.status === "error") {
            if (resdash.message === "Session Expired") {
              localStorage.clear();
              navigate("/login");
              return;
            }
          }
          setstateObj(resdash.items[0]);
        let res3 = await ApiRequest("exchange_report.php", "POST", JSON.stringify({from_date:"",to_date:""}), { Authorization: localStorage.getItem("token") });
        if (res3.status === "error") {
            if (res3.message === "Session Expired") {
                localStorage.clear();
                navigate("/login");
                return;
            }
        }
        setcurrentExchangeRate(res3.items || []);
        return;
        let res1 = await ApiRequest("directsponsorreport.asp", "GET", null, { Authorization: localStorage.getItem("token") });
        if (res1.status === "error") {
            if (res1.message === "Session Expired") {
                localStorage.clear();
                navigate("/login");
                return;
            }
        }
        setoverAllAmount(res1.items || []);
        let res2 = await ApiRequest("directsponsorreport.asp", "GET", null, { Authorization: localStorage.getItem("token") });
        if (res2.status === "error") {
            if (res2.message === "Session Expired") {
                localStorage.clear();
                navigate("/login");
                return;
            }
        }
        setoverAllCurrentAmount(res2.items || []);
        
        let res4 = await ApiRequest("directsponsorreport.asp", "GET", null, { Authorization: localStorage.getItem("token") });
        if (res4.status === "error") {
            if (res4.message === "Session Expired") {
                localStorage.clear();
                navigate("/login");
                return;
            }
        }
        settop10Investors(res4.items || []);
    }

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
        <div class="col-lg-4 mb-4">
          <div class="card h-100">
            <div class="card-body text-center">
              <div class="badge rounded-pill p-2 bg-label-primary mb-2">
                <i class="ti ti-replace ti-sm"></i>
              </div>
              <h5
                class="card-title mb-2"
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {stateObj.total_investment || 0}
              </h5>
              <small>Total Investment</small>
              <br />
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-4">
          <div class="card h-100">
            <div class="card-body text-center">
              <div class="badge rounded-pill p-2 bg-label-success mb-2">
                <i class="ti ti-replace ti-sm"></i>
              </div>
              <h5
                class="card-title mb-2"
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {stateObj.total_close_investment || 0}
              </h5>
              <small>Total Close Investment</small>
              <br />
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-4">
          <div class="card h-100">
            <div class="card-body text-center">
              <div class="badge rounded-pill p-2 bg-label-warning mb-2">
                <i class="ti ti-replace ti-sm"></i>
              </div>
              <h5
                class="card-title mb-2"
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {stateObj.total_profit_paid || 0}
              </h5>
              <small>Total Profit Paid</small>
              <br />
            </div>
          </div>
        </div>
      </div>
            {/* <div className="row mt-5">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <DataTable
                        title="Countries"
                        data={countries}
                        heads={[
                            { name: "Name", fieldname: "name" },
                            { name: "Capital", fieldname: "capital" },
                            { name: "Currency", fieldname: "currency" },
                            { name: "Currency Symbol", fieldname: "currency_symbol" },
                            { name: "Native", fieldname: "native" },
                            { name: "Region", fieldname: "region" }
                        ]} />
                </div>
            </div> */}
            {/* <div className="row mt-5">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <DataTable
                        data={headOfInvesters}
                        title={"Head Of Investors"}
                        heads={[
                            { name: "Code", fieldname: "head_id" },
                            { name: "Name", fieldname: "name" },
                            { name: "Currency", fieldname: "head_currency" }
                        ]} />
                </div>
            </div> */}
            {/* <div className="row mt-5">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <DataTable
                        data={overAllAmount}
                        title={"Head Overall Upload Amount"}
                        heads={[
                            { name: "Country", fieldname: "country" },
                            { name: "Code", fieldname: "code" },
                            { name: "Uploaded", fieldname: "uploaded" },
                        ]} />
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <DataTable
                        title={"Current Amount of Overall Investors Investment Amount Currency Wise"}
                        data={overAllCurrentAmount}
                        heads={[
                            { name: "Currency", fieldname: "currency" },
                            { name: "Date", fieldname: "date" },
                            { name: "Head Amount", fieldname: "headamount" },
                            { name: "Investor Amount", fieldname: "investoramount" },
                            { name: "Ex-Rate", fieldname: "exrate" },
                            { name: "Current", fieldname: "Current" }
                        ]} />
                </div>
            </div> */}
            {/* <div className="row mt-5">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <DataTable
                        title={"Current Currency Exchange Rate"}
                        data={currentExchangeRate}
                        heads={[
                            { name: "From", fieldname: "from_country_currency" },
                            { name: "To", fieldname: "to_country_currency" },
                            { name: "Actual", fieldname: "actual_exchange_rate" },
                            { name: "Investment", fieldname: "investment_exchange_rate" },
                            { name: "Closing", fieldname: "closing_investment_exchange_rate" }
                        ]}/>
                </div>
            </div> */}
            {/* <div className="row mt-5">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <DataTable
                        title={"Top 10 Investors"}
                        data={top10Investors}
                        heads={[
                            { name: "Country", fieldname: "country" },
                            { name: "Investor", fieldname: "investor" },
                            { name: "Invested", fieldname: "invested" }
                        ]} />
                </div>
            </div> */}
        </div>
    )
}

export default Dashboard
