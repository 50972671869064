import React, { useEffect, useState } from "react";
import ApiRequest from "../../Common/restservice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../Common/showtoast";
import DataTable from "../../Components/DataTable";
import moment from "moment";
import Swal from "sweetalert2";

function InvestorsInvestmentslist() {
  const { investorid } = useParams();
  const [tableData, setTableData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    GetTeamList();
  }, []);
  const GetTeamList = async () => {
    let jsoninput = {
      from_date: fromDate ? moment(fromDate).format("YYYY/MM/DD") : "",
      to_date: toDate ? moment(toDate).format("YYYY/MM/DD") : "",
      investor_id: investorid,
    };
    let url = "user_investment2.php";
    let res = await ApiRequest(url, "POST", JSON.stringify(jsoninput), {
      Authorization: localStorage.getItem("token"),
    });
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      setTableData([]);
      return;
    }
    setTableData(res.items);
  };
  const DeleteorBlockProfit = async (id, isblock) => {
    let url = isblock ? "profit_status.php" : "delete_profit.php";
    let jsoninput = { id };
    if (isblock) {
      jsoninput.status = isblock === "1" ? "0" : "1";
    }
    let res = await ApiRequest(url, "POST", JSON.stringify(jsoninput), {
      Authorization: localStorage.getItem("token"),
    });
    if (res.status === "error") {
      errorToast(res.message);
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      setTableData([]);
      return;
    }
    successToast(res.message);
    GetTeamList();
  };
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="card mb-2">
            <div className="card-header">
              <h4 className="card-title text-color-1 mb-0">Search Profit</h4>
            </div>
            <div className="card-body">
              <div
                className="d-md-flex mb-3"
                style={{ justifyContent: "space-evenly", alignItems: "end" }}
              >
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label">From Date</label>
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control daterange-single"
                        id="start_date"
                        name="start_date"
                        placeholder="From Date"
                        autoComplete="off"
                        onChange={(e) => {
                          setFromDate(e.target.value);
                        }}
                        value={fromDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label">To Date</label>
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control daterange-single"
                        id="end_date"
                        name="end_date"
                        placeholder="To Date"
                        autoComplete="off"
                        onChange={(e) => {
                          setToDate(e.target.value);
                        }}
                        value={toDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 text-left">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      onClick={(e) => {
                        GetTeamList();
                      }}
                    >
                      {" "}
                      Search{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            title={"Profit List"}
            heads={[
              {
                name: "Document",
                fieldname: "investment_id",
                view: (item) => (
                  <h6 className="mb-1 text-primary">{item.investment_id}</h6>
                ),
              },
              {
                name: "Invest Date",
                fieldname: "investment_date",
                view: (item) => (
                  <label>
                    {item.investment_date
                      ? moment(item.investment_date).format("DD/MM/YYYY")
                      : ""}
                  </label>
                ),
              },
              {
                name: "Investor Name",
                fieldname: "investor_name",
                view: (item) => (
                  <label>
                    {item.investor_name}/{item.investor_currency}
                  </label>
                ),
              },
              {
                name: "Investor Amount",
                fieldname: "investor_amount",
                view: (item) => (
                  <label>
                    {item.symbol}{" "}
                    {item.investor_amount &&
                      parseFloat(item.investor_amount).toFixed(2)}
                  </label>
                ),
              },
              {
                name: "Task",
                view: (item) => (
                  <div className="d-flex justify-content-around">
                    {(item.status !== "2" && item.investor_id !== localStorage.getItem("username")) && (
                      <Link
                        title="Add Profit"
                        className={`badge bg-success`}
                        style={{ cursor: "pointer" }}
                        to={"/addprofit/" + item.investment_id}
                      >
                        <i className="fas fa-plus" />
                      </Link>
                    )}
                    <Link
                      title="View Profit"
                      className={`badge bg-info ms-2`}
                      style={{ cursor: "pointer" }}
                      to={"/viewprofit/" + item.investment_id}
                    >
                      <i className="fas fa-eye" />
                    </Link>
                    <Link
                      title="View Statement"
                      className={`badge bg-warning ms-2`}
                      style={{ cursor: "pointer" }}
                      to={"/viewstatement/" + item.investment_id}
                    >
                      <i className="fas fa-history" />
                    </Link>
                    {/* {item.status !== "2" && (
                      <label
                        title="Close Investment"
                        className={`badge bg-danger ms-2`}
                        style={{ cursor: "pointer" }}
                        onClick={async (e) => {
                          let res = await Swal.fire({
                            title: "Delete",
                            text: "Are you sure you want to close investment?", 
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                            showCancelButton: true,
                          });
                          if (res.isConfirmed){
                            let res = await ApiRequest(
                              "close_investment.php",
                              "POST",
                              JSON.stringify({
                                investment_id: item.investment_id,
                              }),
                              {
                                Authorization: localStorage.getItem("token"),
                              }
                            );
                            if (res.status === "error") {
                              errorToast(res.message);
                              if (
                                res.message === "Session Expired" ||
                                res.message === "Token expired or invalid"
                              ) {
                                localStorage.clear();
                                navigate("/login");
                              }
                              return;
                            }
                            successToast(res.message);
                            GetTeamList();
                          }
                        }} 
                      >
                        <i className="fas fa-times" />
                      </label>
                    )} */}
                  </div>
                ),
              },
            ]}
            data={tableData}
          />
        </div>
      </div>
    </div>
  );
}

export default InvestorsInvestmentslist;
