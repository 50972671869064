import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { errorToast, successToast } from '../Common/showtoast';
import ApiRequest from '../Common/restservice';

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const [theme, setTheme] = useState(sessionStorage.getItem('theme') || 'light');
    useEffect(() => {
        sessionStorage.setItem('theme', theme);
        if (theme === "light") {
            document.querySelector('html').classList.remove('dark-style');
            document.querySelector('html').classList.add('light-style');
            let ele = document.querySelector('#themestyle');
            let link = ele.getAttribute('href');
            link = link.replace('-dark', '');
            ele.setAttribute('href', link);

            let ele2 = document.querySelector('#themestyle1');
            let link2 = ele2.getAttribute('href');
            link2 = link2.replace('-dark', '');
            ele2.setAttribute('href', link2);
        } else {
            document.querySelector('html').classList.add('dark-style');
            document.querySelector('html').classList.remove('light-style');
            let ele = document.querySelector('#themestyle');
            let link = ele.getAttribute('href');
            if (!link.includes('-dark')) {
                link = link.replace('.css', '-dark.css');
                ele.setAttribute('href', link);
            }

            let ele2 = document.querySelector('#themestyle1');
            let link2 = ele2.getAttribute('href');
            if (!link2.includes('-dark')) {
                link2 = link2.replace('.css', '-dark.css');
                ele2.setAttribute('href', link2);
            }
        }
    }, [theme])
    return (
        <div class="container-xxl">
            <div class="authentication-wrapper authentication-basic container-p-y">
                <div class="authentication-inner py-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="app-brand justify-content-center mb-4 mt-2">
                                <a href="index.html" class="app-brand-link gap-2">
                                <span class="app-brand-logo demo">
                                        <img src={require('../Assets/Images/logo-dark.png')} style={{ width: 30, height: 30 }} />
                                    </span>
                                    <div className="app-brand-text demo menu-text fw-bold">
                                        <h4 className='mb-0'>DEDICO</h4>
                                        <h4 className='mb-0' style={{ fontSize: 8 }}>NETWORK PRIVATE LIMITED</h4>
                                    </div>
                                </a>
                            </div>
                            <h4 class="mb-1 pt-2">Forgot Password? 🔒</h4>
                            <p class="mb-4">Enter your email and we'll send you instructions to reset your password</p>
                            <form id="formAuthentication" class="mb-3" onSubmit={async (e) => {
                                e.preventDefault();
                                if (!email) {
                                    errorToast("Enter valid email");
                                    return;
                                }
                                let jsoninput = {
                                    username: email
                                }
                                let res = await ApiRequest("ForgotPassword.asp", "POST", JSON.stringify(jsoninput));
                                if (res.status === "error") {
                                    errorToast(res.message);
                                    return;
                                }
                                successToast(res.message);
                                navigate("/login");
                            }}>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Email</label>
                                    <input type="email" name="email" id="email" className="form-control" placeholder="Enter your email address" onChange={(e) => {
                                        setEmail(e.target.value);
                                    }} value={email} />
                                </div>
                                <button class="btn btn-success d-grid w-100">Send Reset Link</button>
                            </form>
                            <div class="text-center">
                                <Link to={"/"} class="d-flex align-items-center justify-content-center">
                                    <i class="ti ti-chevron-left scaleX-n1-rtl"></i>
                                    Back to login
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword