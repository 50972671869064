import React, { useEffect, useState } from "react";
import ApiRequest from "../../Common/restservice";
import { errorToast, successToast } from "../../Common/showtoast";
import { useNavigate, useParams } from "react-router-dom";
import { GetCountries } from "react-country-state-city";

function AddInvestors() {
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();
  const { investorid } = useParams();
  const [stateObj, setStateObj] = useState({
    regno: "",
    country: "",
    date: "",
    time: "",
    currency: "",
    investorname: "",
    username: "",
    mobileno: "",
    password: "",
    emailid: "",
    file1: "",
    file2: "",
    file2preview: "",
    file1preview: "",
    file1name: "",
    file2name: "",
    permission:"readonly"
  });
  useEffect(() => {
    GetCurrencyAndCountry();
  }, [investorid]);
  const GetCurrencyAndCountry = async () => {
    let currency = await GetCountries();
    setCountries(currency);
    if (investorid) {
      let res1 = await ApiRequest(
        "get_investor.php",
        "POST",
        JSON.stringify({ id: investorid }),
        { Authorization: localStorage.getItem("token") }
      );
      if (res1.status === "error") {
        if (res1.message === "Session Expired") {
          localStorage.clear();
          navigate("/login");
        }
        return;
      }
      let item = res1.items[0];
      setStateObj({
        investorname: item.name,
        mobileno: item.mobile,
        emailid: item.email,
        country: currency.findIndex((e) => e.name === item.country),
        currency: currency.findIndex((e) => e.currency === item.currency),
        username: item.username,
        password: item.password,
        file1preview: item.idfronturl,
        file2preview: item.idbackurl,
        file1name: item.idfront,
        file2name: item.idback,
        permission: item.permission_status === "1" ? "readandwrite" : "readonly"
      });
    }
  };
  const Update = async () => {
    if (!stateObj.country) {
      errorToast("Enter valid country");
      return;
    } else if (!stateObj.currency) {
      errorToast("Enter valid currency");
      return;
    } else if (!stateObj.investorname) {
      errorToast("Enter valid investorname");
      return;
    } else if (!stateObj.username) {
      errorToast("Enter valid username");
      return;
    } else if (!stateObj.mobileno) {
      errorToast("Enter valid mobileno");
      return;
    } else if (!stateObj.password) {
      errorToast("Enter valid password");
      return;
    } else if (!stateObj.emailid) {
      errorToast("Enter valid emailid");
      return;
    }
    if (investorid) {
      if (!stateObj.file1) {
        errorToast("Select valid front image");
        return;
      } else if (!stateObj.file2) {
        errorToast("Select valid back image");
        return;
      }
    }
    // let jsoninput = {
    //     "name": stateObj.investorname,
    //     "mobile": stateObj.mobileno,
    //     "email": stateObj.emailid,
    //     "country": countries[stateObj.country]?.name || "",
    //     "currency": countries[stateObj.country]?.currency || "",
    //     "username": stateObj.username,
    //     "password": stateObj.password
    // }
    console.log(stateObj);
    const formData = new FormData();
    formData.append("name", stateObj.investorname);
    formData.append("mobile", stateObj.mobileno);
    formData.append("email", stateObj.emailid);
    formData.append("country", countries[stateObj.country]?.name || "");
    formData.append("currency", countries[stateObj.currency]?.currency || "");
    formData.append("username", stateObj.username);
    formData.append("password", stateObj.password);
    formData.append("permission", stateObj.permission === "readandwrite" ? "1" : "0");
    if (stateObj.file1preview && stateObj.file1) {
      formData.append("idfront", stateObj.file1);
      formData.append("idfrontname", "");
    } else if (stateObj.file1name) {
      formData.append("idfrontname", stateObj.file1name);
    }
    if (stateObj.file2preview && stateObj.file2) {
      formData.append("idback", stateObj.file2);
      formData.append("idbackname", "");
    } else if (stateObj.file2name) {
      formData.append("idbackname", stateObj.file2name);
    }
    if (investorid) {
      formData.append("id", investorid);
    }
    console.log(formData);
    let res = await ApiRequest(
      investorid ? "edit_investor.php" : "add_investor.php",
      "POST",
      formData,
      { Authorization: localStorage.getItem("token") }
    );
    if (res.status === "error") {
      errorToast(res.message);
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      return;
    }
    successToast(res.message);
    navigate("/investorslist");
  };
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="card overflow-hidden dash1-card border-0 ">
          <form
            className="card-body "
            onSubmit={(e) => {
              e.preventDefault();
              Update();
            }}
          >
            <h3 className=" mt-3 mb-3">Investor Registration Form</h3>
            <div className="row">
              {/* <div className="col-lg-6 row mb-3">
                                <h6 className='mb-1 ml-4'>Reg NO</h6>
                                <div className="input-container">
                                    <input type="text" className="form-control col-lg-4" placeholder="Reg NO" onChange={(e) => {
                                        setStateObj({
                                            ...stateObj, regno: e.target.value
                                        })
                                    }} value={stateObj.regno} />
                                </div>
                            </div> */}
              <div className="col-lg-6">
                <div className="row mb-4">
                  <h6 className="mb-1 ml-4">Investor Name</h6>
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control col-lg-4"
                      placeholder="Investor name"
                      onChange={(e) => {
                        setStateObj({
                          ...stateObj,
                          investorname: e.target.value,
                        });
                      }}
                      value={stateObj.investorname}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <h6 className="mb-1 ml-4">Mobile No</h6>
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control col-lg-4"
                      placeholder="Enter mobile no"
                      onChange={(e) => {
                        setStateObj({
                          ...stateObj,
                          mobileno: e.target.value,
                        });
                      }}
                      value={stateObj.mobileno}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <h6 className="mb-1 ml-4">Email Id</h6>
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control col-lg-4"
                      placeholder="Enter valid emailid"
                      onChange={(e) => {
                        setStateObj({
                          ...stateObj,
                          emailid: e.target.value,
                        });
                      }}
                      value={stateObj.emailid}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <h6 className="mb-1 ml-4">Country</h6>
                  <div className="input-container">
                    <select
                      className="form-control"
                      placeholder="Select Country"
                      onChange={(e) => {
                        setStateObj({
                          ...stateObj,
                          country: e.target.value,
                          currency: e.target.value,
                        });
                      }}
                      value={stateObj.country}
                    >
                      <option value={""}>-- Select Country--</option>
                      {countries.map((item, index) => (
                        <option key={item.name + item.index} value={index}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-4">
                  <h6 className="mb-1 ml-4">Currency</h6>
                  <div className="input-container">
                    <select
                      className="form-control"
                      placeholder="Select Country"
                      onChange={(e) => {
                        setStateObj({
                          ...stateObj,
                          currency: e.target.value,
                        });
                      }}
                      value={stateObj.currency}
                    >
                      <option value={""}>-- Select Currency--</option>
                      {countries.map((item, index) => (
                        <option key={item.currency + item.index} value={index}>
                          {item.currency}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6 row mb-4">
                                <h6 className='mb-1 ml-4'>Date & Time</h6>
                                <div className="input-group">

                                    <input type="date" className="form-control" placeholder="Date" onChange={(e) => {
                                        setStateObj({
                                            ...stateObj, date: e.target.value
                                        })
                                    }} value={stateObj.date} />
                                    <input type="time" className="form-control" placeholder="Time" onChange={(e) => {
                                        setStateObj({
                                            ...stateObj, time: e.target.value
                                        })
                                    }} value={stateObj.time} />
                                </div>
                            </div> */}
              <div className="col-lg-6 ">
                <div className="row mb-4">
                  <h6 className="mb-1 ml-4">User name</h6>
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control col-lg-4"
                      placeholder="Username"
                      onChange={(e) => {
                        setStateObj({
                          ...stateObj,
                          username: e.target.value,
                        });
                      }}
                      value={stateObj.username}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <h6 className="mb-1 ml-4">Password</h6>
                  <div className="input-container">
                    <input
                      type="password"
                      className="form-control col-lg-4"
                      placeholder="Enter valid password"
                      onChange={(e) => {
                        setStateObj({
                          ...stateObj,
                          password: e.target.value,
                        });
                      }}
                      value={stateObj.password}
                    />
                  </div>
                </div>
                {/* <div className="row mb-4">
                  <h6 className="mb-1 ml-4">Investor Permission</h6>
                  <div className="input-container d-flex align-items-center">
                    <input
                      type="radio"
                      name="permission"
                      id="readandwrite"
                      className="form-radio-control"
                      onChange={(e) => {
                        setStateObj({
                          ...stateObj,
                          permission: e.target.value,
                        });
                      }}
                      value={"readandwrite"}
                      checked={stateObj.permission === "readandwrite"}
                    />
                    <label className="ms-2" htmlFor="readandwrite">
                      Read and Write
                    </label>
                    <input
                      type="radio"
                      name="permission"
                      id="readonly"
                      className="form-radio-control ms-3"
                      onChange={(e) => {
                        setStateObj({
                          ...stateObj,
                          permission: e.target.value,
                        });
                      }}
                      value={"readonly"}
                      checked={stateObj.permission === "readonly"}
                    />
                    <label className="ms-2" htmlFor="readonly">
                      Read Only
                    </label>
                  </div>
                </div> */}
                <div className="mb-4 row">
                  <div className="col-lg-6">
                    <h6 className="mb-1 ml-4">ID Front</h6>
                    <label htmlFor="idfront">
                      <img
                        style={{ width: "100%", maxHeight: 150 }}
                        src={
                          stateObj.file1preview
                            ? stateObj.file1preview
                            : require("../../Assets/Images/default.png")
                        }
                      />
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      id="idfront"
                      className="form-control col-lg-4"
                      placeholder="Select Front Image"
                      onChange={(e) => {
                        let previewurl = "";
                        if (e.target.files[0]) {
                          previewurl = URL.createObjectURL(e.target.files[0]);
                        }
                        setStateObj({
                          ...stateObj,
                          file1: e.target.files[0],
                          file1preview: previewurl,
                        });
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <h6 className="mb-1 ml-4">ID Back</h6>
                    <label htmlFor="idback">
                      <img
                        style={{ width: "100%", maxHeight: 150 }}
                        src={
                          stateObj.file2preview
                            ? stateObj.file2preview
                            : require("../../Assets/Images/default.png")
                        }
                      />
                    </label>
                    <input
                      type="file"
                      id="idback"
                      accept="image/*"
                      hidden
                      className="form-control col-lg-4"
                      placeholder="Select Back Image"
                      onChange={(e) => {
                        let previewurl = "";
                        if (e.target.files[0]) {
                          previewurl = URL.createObjectURL(e.target.files[0]);
                        }
                        setStateObj({
                          ...stateObj,
                          file2: e.target.files[0],
                          file2preview: previewurl,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button className="ml-2 mb-5 btn btn-success" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddInvestors;
