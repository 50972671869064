import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './Assets/Css/fontawesome.css';
import './Assets/Css/tabler-icons.css';
import './Assets/Css/demo.css';
import './Assets/Css/node-waves.css';
import './Assets/Css/typeahead.css';
import './Assets/Css/perfect-scrollbar.css';
import './Assets/Css/cards-advance.css';
import './Assets/Css/page-auth.css';
import 'react-toastify/dist/ReactToastify.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Login from './Pages/Login';
import ForgotPassword from './Pages/ForgotPassword';
import Register from './Pages/Register';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <ToastContainer />
    <Routes>
      <Route path='/*' element={<App />} />
      <Route path='/' element={<Login />} />
      <Route path='/login' element={<Login />} />
      <Route path='/forgotpassword' element={<ForgotPassword />} />
      <Route path='/register' element={<Register />} />
    </Routes>
  </HashRouter>
);