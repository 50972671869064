import React, { useEffect, useState } from 'react'
// import PageHeader from '../components/pageheader'
import { useNavigate } from 'react-router-dom';
import ApiRequest from '../Common/restservice';

function Directsponsor() {
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        // GetDirectSponsors();
    }, [])
    const GetDirectSponsors = async () => {
        let jsoninput = {
            memberid: localStorage.getItem("username")
        };
        let res = await ApiRequest("directsponsorreport.asp", "POST", JSON.stringify(jsoninput), { Authorization: localStorage.getItem("token") });
        if (res.status === "error") {
            if (res.message === "Session Expired" || res.message === "Token expired or invalid") {
                localStorage.clear();
                navigate("/login");
            }
            return;
        }
        setTableData(res.items);
    }

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            {/* <PageHeader title="Direct Sponsor" /> */}
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title text-color-1" style={{ fontWeight: "200", fontSize: "22px" }}>
                                Direct Sponsors List
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-vcenter text-nowrap mb-0 table-striped table-bordered border-top" style={{ textAlign: "center" }}>
                                    <thead className=''>
                                        <tr >
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th>Ac. No</th>
                                            <th>Date</th>
                                            <th>Package</th>
                                            <th>Direct Referral</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tableData.length <= 0 ? <tr>
                                                <td colSpan="6">No Details Found</td>
                                            </tr> :
                                                tableData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{(index + 1)}</td>
                                                        <td>{item.fullname}</td>
                                                        <td>{item.memberid}</td>
                                                        <td>{item.joindate}</td>
                                                        <td>{item.package}</td>
                                                        <td>{item.refferalcount}</td>

                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Directsponsor
