import React, { useEffect, useState } from "react";
import ApiRequest from "../../Common/restservice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../Common/showtoast";
import DataTable from "../../Components/DataTable";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { GetCountries } from "react-country-state-city";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
let months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
function ViewStatement() {
  const [years, setYears] = useState([]);
  const { investmentid } = useParams();
  const [tableData, setTableData] = useState([]);
  const [totalAmount, settotalAmount] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [invInfo, setinvInfo] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    GetTableData();
  }, []);
  const handleDownloadPdf = () => {
    const input = document.getElementById("statement");
    html2canvas(input, { scale: 2 }) // Adjust the scale as needed
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.7); // Reduce image quality to 70%
        const pdf = new jsPDF("p", "mm", "a4"); // Specify PDF dimensions
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
        pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
        pdf.save(`statement_${investmentid}.pdf`);
      });
  };
  const GetTableData = async () => {
    let jsoninput = {
      from_date: fromDate ? moment(fromDate).format("YYYY/MM/DD") : "",
      to_date: toDate ? moment(toDate).format("YYYY/MM/DD") : "",
      id: investmentid,
    };
    let res = await ApiRequest(
      "view_statement.php",
      "POST",
      JSON.stringify(jsoninput),
      { Authorization: localStorage.getItem("token") }
    );
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
        return;
      }
      setTableData([]);
      return;
    }
    let arr = [];
    let item = res.items[0];
    setinvInfo(item);
    let amount = 0; //parseFloat(item.investor_amount);
    arr.push({
      date: item.investment_date,
      remark: item.remark,
      debit: parseFloat(item.investor_amount),
      credit: 0,
      balance: amount,
      tid: item.investment_id,
      reasonremark: "Investment",
      reasonremark1: "",
    });
    for (let x of item.profits_list) {
      let json = JSON.parse(x.profit);
      if (json.length > 0) {
        for (let y of json) {
          if ((y.profit && parseFloat(y.profit) > 0) || y.cdate) {
            console.log(y);
            arr.push({
              date: y.cdate || y.profit_date,
              remark: y.remark,
              debit: 0,
              credit: y.profit,
              balance: amount,
              tid: x.profit_id,
              reasonremark: "Profit",
              reasonremark1: y.remark,
            });
          }
        }
      }
    }
    for (let x of item.payment_list) {
      arr.push({
        date: x.cdate,
        remark: x.remark,
        debit: 0,
        credit: x.amount,
        balance: amount,
        tid: x.payment_id,
        reasonremark: x.reason,
        
        reasonremark1: null,
      });
    }
    if (item.status === "2") {
      arr.push({
        date: item.udate,
        remark: item.remark,
        credit: parseFloat(item.investor_amount),
        debit: 0,
        balance: amount,
        tid: item.investment_id,
        reasonremark: "Investment",
        reasonremark1: item.remark,
      });
    }
    arr.sort((a, b) => moment(a.date).unix() - moment(b.date).unix());
    for (let ar of arr) {
      if (ar.credit) {
        amount += parseFloat(ar.credit);
        ar.balance = amount;
      }
      //   else if (ar.debit) {
      //     amount -= parseFloat(ar.debit);
      //     ar.balance = amount;
      //   }
    }
    settotalAmount(amount);
    arr.sort((a, b) => moment(b.date).unix() - moment(a.date).unix());
    setTableData(arr);
  };
  return (
    <div className="container-xxl flex-grow-1 container-p-y ">
      <div className="card-body">
        <div
          className="d-md-flex mb-3"
          style={{ justifyContent: "space-evenly", alignItems: "end" }}
        >
          <div className="col-md-3">
            <div className="form-group">
              <label className="form-label">From Date</label>
              <div className="input-group">
                <input
                  type="date"
                  className="form-control daterange-single"
                  id="start_date"
                  name="start_date"
                  placeholder="From Date"
                  autoComplete="off"
                  onChange={(e) => {
                    setFromDate(e.target.value);
                  }}
                  value={fromDate}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="form-label">To Date</label>
              <div className="input-group">
                <input
                  type="date"
                  className="form-control daterange-single"
                  id="end_date"
                  name="end_date"
                  placeholder="To Date"
                  autoComplete="off"
                  onChange={(e) => {
                    setToDate(e.target.value);
                  }}
                  value={toDate}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3 text-left">
            <div className="form-group text-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  GetTableData();
                }}
              >
                {" "}
                Search{" "}
              </button>
            </div>
          </div>
          <div className="col-md-3 text-left">
            <div className="form-group text-center">
              <button
                className="btn btn-primary"
                style={{ marginLeft: "auto" }}
                onClick={handleDownloadPdf}
              >
                Download PDF
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row" id="statement">
        <div className="col-xl-12 col-lg-12 col-md-12  mb-2">
          <div className="card">
            <div className="card-body row">
              <div className="col-lg-5">
                <p>
                  Investor Name :
                  <span style={{ fontWeight: "bold", fontSize: 16 }}>
                    {" "}
                    {invInfo.investor_name}
                  </span>
                </p>
                <p>
                  Investment Amount :{" "}
                  <span style={{ fontWeight: "bold", fontSize: 16 }}>
                    {" "}
                    {invInfo.investor_amount} {invInfo.investor_currency}{" "}
                  </span>
                </p>
                <p>
                  Head Name :{" "}
                  <span style={{ fontWeight: "bold", fontSize: 16 }}>
                    {" "}
                    {invInfo.head_name}
                  </span>
                </p>
              </div>
              <div className="col-lg-4">
                <p>
                  {" "}
                  Investment ID :{" "}
                  <span style={{ fontWeight: "bold", fontSize: 16 }}>
                    {invInfo.investment_id}
                  </span>
                </p>
                <p>
                  Investment Date :{" "}
                  <span style={{ fontWeight: "bold", fontSize: 16 }}>
                    {" "}
                    {invInfo.investment_date &&
                      moment(invInfo.investment_date).format("DD MMMM YYYY")}
                  </span>
                </p>
                {/*<p>
                  Exchange Rate :{" "}
                  <span style={{ fontWeight: "bold", fontSize: 16 }}>
                    {" "}
                    {invInfo.exchange_rate}
                  </span>
                </p>*/}
              </div>
              <div className="col-lg-3">
                
              <img src={require('../../Assets/Images/dedicologo.png')}  />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12">
          <DataTable
            title={"Statements of accounts"}
            headerRight={`Total Amount : ${totalAmount+` `+ invInfo.investor_currency}`}
            itemsperpage={200}
            hideitemspage={true}
            hidepagenation={true}
            rowbg={true}
            searchEnabled={false}
            tooltip="remark"
            heads={[
              {
                name: "Particulars",
                fieldname: "Particulars",
                view: (item) => (
                  <div className="text-start">
                    {/* <h5 className="mb-0">{item.remark}</h5> */}
                    <label style={{ fontSize: 14 }}>
                      {moment(item.date).format("DD MMMM yyyy")} - Transaction :
                      &nbsp;
                      {item.reasonremark}-{item.tid}
                      &nbsp; 
                      {item.reasonremark1 == null ? `` : item.reasonremark1 != "" ? `- ( ${item.reasonremark1} )`  : `- ( ${item.remark} )`}
                    </label>
                  </div>
                ),
              },
              {
                name: "Credit",
                fieldname: "credit",
                view: (item) => (
                  <label>
                    {item.credit && `+${parseFloat(item.credit).toFixed(2)}`}
                  </label>
                ),
              },
              {
                name: "Debit",
                fieldname: "debit",
                view: (item) => (
                  <label>
                    {item.debit && `-${parseFloat(item.debit).toFixed(2)}`}
                  </label>
                ),
              },
              //   {
              //     name: "Balance",
              //     fieldname: "balance",
              //   },
            ]}
            data={tableData}
          />
          {/* <div className="row mt-2 col-lg-12">
            <h6>Current Year Profit : {overalltotal}</h6>
            <h6>Profit Paid : {profittotal}</h6>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ViewStatement;
