import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import ApiRequest from '../Common/restservice';
import { errorToast } from '../Common/showtoast';
import { GetCountries } from 'react-country-state-city';
import Swal from 'sweetalert2';
function Register() {
    const [theme, setTheme] = useState(sessionStorage.getItem('theme') || 'light');
    const [countries, setCountries] = useState([]);
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [password, setPassword] = useState("");
    const [sponsorname, setSponsorname] = useState("");
    const [confirmpassword, setconfirmpassword] = useState("");
    const [mobile, setmobile] = useState("");
    const [name, setname] = useState("");
    const [sponsorid, setsponsorid] = useState("");
    const [country, setcountry] = useState("");
    const [countrycode, setcountrycode] = useState("");
    const [terms, setterms] = useState("");
    const navigate = useNavigate();
    const { sponsorid: sid } = useParams();
    useEffect(() => {
        GetCountries().then(res => {
            if (res.length > 0) {
                let cos = [res.find((e) => e.name === "Malaysia")];
                res.forEach(element => {
                    if (element.name !== "Malaysia") {
                        cos.push(element);
                    }
                });
                setCountries(cos);
            }
        });
    }, [])
    useEffect(() => {
        setsponsorid(sid);
        getName(sid);
    }, [sid])
    const getName = async (memberid) => {
        if (!memberid) {
            return;
        }
        let jsoninput = {
            memberid: memberid
        }
        console.log(jsoninput);
        let res = await ApiRequest("getname.asp", "POST", JSON.stringify(jsoninput));
        if (res.status === "error") {
            return;
        }
        setSponsorname(res.fullname);
    }
    const OnRegister = async (e) => {
        if (!username) {
            errorToast("Enter valid username");
            return;
        } else if (username.length < 6 || username.length > 15) {
            errorToast("Username must be between 6 to 15 characters");
            return;
        } else if (!email) {
            errorToast("Enter valid email");
            return;
        } else if (!name) {
            errorToast("Enter valid name");
            return;
        }
        else if (!mobile) {
            errorToast("Enter valid mobile");
            return;
        } else if (!sponsorid) {
            errorToast("Enter valid sponsorid");
            return;
        } else if (!password) {
            errorToast("Enter valid password");
            return;
        } else if (!confirmpassword) {
            errorToast("Enter valid confirmpassword");
            return;
        } else if (password !== confirmpassword) {
            errorToast("Password Mismatched");
            return;
        } else if (!terms) {
            errorToast("Please read and accept terms and conditions");
            return;
        }
        let jsoninput = {
            email: email,
            sponsorid: sponsorid,
            mobile: mobile,
            password: password,
            fullname: name,
            country: country,
            username
        }
        let res = await ApiRequest("actReg.asp", "POST", JSON.stringify(jsoninput));
        if (res.status === "error") {
            errorToast(res.message);
            return;
        }
        await Swal.fire({
            title: 'Registered Successfully',
            html: `<div class='text-center'><label class='mb-3'>Please notedown your userid and password for the future purpose.</label><br><p>User ID : <span class='text-success'>${username}</span></p><p>Password : <span class='text-success'>${password}</span></p></div>`
        })
        navigate('/login');
    }
    useEffect(() => {
        sessionStorage.setItem('theme', theme);
        if (theme === "light") {
            document.querySelector('html').classList.remove('dark-style');
            document.querySelector('html').classList.add('light-style');
            let ele = document.querySelector('#themestyle');
            let link = ele.getAttribute('href');
            link = link.replace('-dark', '');
            ele.setAttribute('href', link);

            let ele2 = document.querySelector('#themestyle1');
            let link2 = ele2.getAttribute('href');
            link2 = link2.replace('-dark', '');
            ele2.setAttribute('href', link2);
        } else {
            document.querySelector('html').classList.add('dark-style');
            document.querySelector('html').classList.remove('light-style');
            let ele = document.querySelector('#themestyle');
            let link = ele.getAttribute('href');
            if (!link.includes('-dark')) {
                link = link.replace('.css', '-dark.css');
                ele.setAttribute('href', link);
            }

            let ele2 = document.querySelector('#themestyle1');
            let link2 = ele2.getAttribute('href');
            if (!link2.includes('-dark')) {
                link2 = link2.replace('.css', '-dark.css');
                ele2.setAttribute('href', link2);
            }
        }
    }, [theme])
    return (
        <div class="container-xxl">
            <div class="authentication-wrapper authentication-basic container-p-y">
                <div class="authentication-inner py-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="app-brand justify-content-center mb-4 mt-2">
                                <a href="index.html" class="app-brand-link gap-2">
                                <span class="app-brand-logo demo">
                                        <img src={require('../Assets/Images/logo-dark.png')} style={{ width: 30, height: 30 }} />
                                    </span>
                                    <div className="app-brand-text demo menu-text fw-bold">
                                        <h4 className='mb-0'>DEDICO</h4>
                                        <h4 className='mb-0' style={{ fontSize: 8 }}>NETWORK PRIVATE LIMITED</h4>
                                    </div>
                                </a>
                            </div>
                            <h4 class="mb-1 pt-2">Adventure starts here 🚀</h4>
                            <p class="mb-4">Make your app management easy and fun!</p>

                            <form id="formAuthentication" class="mb-3" action="index.html" method="GET">
                                <div class="mb-3">
                                    <label for="email" class="form-label">Sponsorid</label>
                                    <input type="text" value={sponsorid} onChange={(e) => {
                                        setsponsorid(e.target.value);
                                    }} name="sponsorid" id="sponsorid" className="form-control" placeholder="Enter sponsor ID" onBlur={(e) => {
                                        getName(e.target.value)
                                    }} />
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Sponsor name</label>
                                    <input type="text" value={sponsorname} name="sponsorname" id="sponsorname" className="form-control" placeholder="Sponsorname" readOnly />
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">User name</label>
                                    <input type="text" onChange={(e) => {
                                        let regex = /^[a-zA-Z0-9]+$/;
                                        if (e.target.value) {
                                            if (e.target.value.match(regex)) {
                                                setUsername(e.target.value)
                                            }
                                        } else {
                                            setUsername("");
                                        }
                                    }} value={username} name="Username" id="username" className="form-control" placeholder="Enter username" />
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Full name</label>
                                    <input type="text" onChange={(e) => {
                                        setname(e.target.value)
                                    }} value={name} name="fullname" id="fullname" className="form-control" placeholder="Enter Full Name" />
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Email id</label>
                                    <input type="email" onChange={(e) => {
                                        setEmail(e.target.value)
                                    }} value={email} name="email" id="email" className="form-control" placeholder="Enter Email ID" />
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Country</label>
                                    <select className='form-control' onChange={(e) => {
                                        if (e.target.value) {
                                            let obj = countries[e.target.value - 1];
                                            console.log(obj);
                                            setcountry(obj.name);
                                            setSelectedCountry(e.target.value);
                                            setcountrycode(obj.phone_code)
                                        }
                                    }} value={selectedCountry}>
                                        <option value={""}>-- Select Country --</option>
                                        {
                                            countries.map((item, index) => {
                                                return <option key={item.name} value={(index + 1)}>{item.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Mobile</label>
                                    <div className="d-flex">
                                        <input type="text" readOnly value={countrycode ? `+${countrycode}` : ""} name="countrycode" id="countrycode" className="form-control" placeholder="+91" style={{ width: window.innerWidth > 700 ? "15%" : "20%", padding: "10px", }} />
                                        <input type="tel" onChange={(e) => {
                                            if (Number(e.target.value) || !e.target.value)
                                                setmobile(e.target.value)
                                        }} value={mobile} name="mobile" id="mobile" className="form-control" placeholder="Enter mobile number" />
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Email id</label>
                                    <input type="email" onChange={(e) => {
                                        setEmail(e.target.value)
                                    }} value={email} name="email" id="email" className="form-control" placeholder="Enter Email ID" />
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Password</label>
                                    <div className="d-flex">
                                        <input type="password" onChange={(e) => {
                                            setPassword(e.target.value)
                                        }} value={password} name="password" id="password" className="form-control" placeholder="Enter new password" />
                                        <input type="password" onChange={(e) => {
                                            setconfirmpassword(e.target.value)
                                        }} value={confirmpassword} name="confirmpassword" id="confirmpassword" className="form-control" placeholder="Confirm new password" />
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="form-check">
                                        <input onChange={async (e) => {
                                            setterms(!terms);
                                        }} value={terms} class="form-check-input" type="checkbox" id="remember-me" />
                                        <label class="form-check-label" for="remember-me">
                                            Terms and conditions
                                        </label>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <button class="btn btn-success d-grid w-100" type="submit">Sign in</button>
                                </div>
                            </form>

                            <p class="text-center">
                                <span>Already have an account?</span>
                                <Link to={"/"}>
                                    <span> Sign in instead</span>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register