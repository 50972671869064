import React, { useState } from 'react'
import ApiRequest from '../Common/restservice';
import { errorToast, successToast } from '../Common/showtoast';
import { useNavigate } from 'react-router-dom';

function Changepassword() {
    const navigate = useNavigate();
    const [oldpassword, setOldpassword] = useState("");
    const [confirmpassword, setconfirmpassword] = useState("");
    const [newpassword, setnewpassword] = useState("");
    const UpdatePassword = async () => {
        if (!oldpassword) {
            errorToast("Enter valid current password");
            return;
        } else if (!newpassword) {
            errorToast("Enter valid new password");
            return;
        } else if (!confirmpassword) {
            errorToast("Enter valid confirm password");
            return;
        } else if (newpassword !== confirmpassword) {
            errorToast("Password Mismatched");
            return;
        }
        let res = await ApiRequest("updatepassword.php", "POST", JSON.stringify({ oldpassword, newpassword,name:localStorage.getItem('username'),memberid:localStorage.getItem('username') }), { Authorization: localStorage.getItem("token") });
        if (res.status === "error") {
            errorToast(res.message);
            if (res.message === "Session Expired" || res.message === "Token expired or invalid") {
                localStorage.clear();
                navigate("/login");
            }
            return;
        }
        successToast(res.message);
        setOldpassword("");
        setnewpassword("");
        setconfirmpassword("");
    }
    return (
        <div className='container-xxl flex-grow-1 container-p-y'>
            <div className="row">
                <div className="card overflow-hidden dash1-card border-0 ">
                    <form className="card-body " onSubmit={(e) => { e.preventDefault(); UpdatePassword(); }}>
                        <h3 className=" mt-3 mb-3">Change Password</h3>
                        <div className="col-lg-6 row mb-3">
                        <h6 className='mb-1 ml-4'>Current Password</h6>
                            <div className="input-container">
                                <i className="las la-lock inputicon"></i>
                                <input type="password" className="form-control col-lg-4" placeholder="Old password" aria-label="Old password" tabIndex="1" onChange={(e) => {
                                    setOldpassword(e.target.value)
                                }} value={oldpassword} />
                            </div>
                        </div>
                        <div className="col-lg-6 row mb-3">
                        <h6 className='mb-1 ml-4'>New Password</h6>
                            <div className="input-container">
                                <i className="las la-lock inputicon"></i>
                                <input type="password" className="form-control col-lg-4" placeholder="New password" aria-label="New password" tabIndex="1" onChange={(e) => {
                                    setnewpassword(e.target.value);
                                }} value={newpassword} />
                            </div>
                        </div>
                        <div className="col-lg-6 row mb-4">
                        <h6 className='mb-1 ml-4'>Confirm New Password</h6>
                            <div className="input-container">
                                <i className="las la-lock inputicon"></i>
                                <input type="password" className="form-control col-lg-4" placeholder="Confirm new password" aria-label="Confirm new password" tabIndex="1" onChange={(e) => {
                                    setconfirmpassword(e.target.value);
                                }} value={confirmpassword} />
                            </div>
                        </div>
                        <button className="ml-2 mb-5 btn btn-success" type="submit">Update</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Changepassword
