import React, { useEffect, useState } from "react";
import ApiRequest from "../../Common/restservice";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "../../Components/DataTable";
import moment from "moment";

function Profitlist() {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    GetTeamList();
  }, []);
  const GetTeamList = async () => {
    let url = "user_investment1.php";
    let res = await ApiRequest(url, "GET", null, {
      Authorization: localStorage.getItem("token"),
    });
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      setTableData([]);
      return;
    }
    setTableData(res.items);
  };
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <DataTable
            title={"Investors"}
            heads={[
              {
                name: "Investor",
                fieldname: "investor",
                view: (item) => (
                  <div>
                    <h5 className="mb-1">{item.name}</h5>
                    <label style={{ fontSize: "12px" }}>{item.memberid}</label>
                  </div>
                ),
              },
              {
                name: "Currency",
                fieldname: "currency",
              },
              {
                name: "Country",
                fieldname: "country",
              },
              {
                name: "Joined On",
                fieldname: "cdate",
                view: (item) => (
                  <label>
                    {item.cdate && moment(item.cdate).format("DD/MM/YYYY")}
                  </label>
                ),
              },
              {
                name: "Action",
                view: (item) => (
                  <Link
                    className={`badge bg-success`}
                    style={{ cursor: "pointer" }}
                    to={"/investorsinvestments/"+item.memberid}
                  >
                    View Investments
                  </Link>
                ),
              }
            ]}
            data={tableData}
          />
        </div>
      </div>
    </div>
  );
}

export default Profitlist;
