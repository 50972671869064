import React, { useEffect, useState } from "react";
import ApiRequest from "../../Common/restservice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../Common/showtoast";
import DataTable from "../../Components/DataTable";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { GetCountries } from "react-country-state-city";
let months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
function ViewProfit() {
  const [years, setYears] = useState([]);
  const { investmentid } = useParams();
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [stateObj, setStateObj] = useState({});
  const [inputObj, setInputObj] = useState({});
  const [currency, setcurrency] = useState("");
  const [profittotal, setprofittotal] = useState("");
  const [overalltotal, setoveralltotal] = useState("");
  
  const [currencies, setcurrencies] = useState([]);
  const [year, setYear] = useState(moment().year());
  const navigate = useNavigate();
  useEffect(()=>{
    GetCountries().then((res)=>{
      setcurrencies(res);
    })
  },[])
  useEffect(() => {
    if (year && stateObj) {
      GetTableData();
    }
  }, [year, stateObj]);
  useEffect(() => {
    let arr = [];
    for (let i = 0; i < 50; i++) {
      arr.push(2000 + i);
    }
    setYears(arr);
    GetTeamList();
  }, []);
  const GetTeamList = async () => {
    let res = await ApiRequest(
      "get_investment1.php",
      "POST",
      JSON.stringify({ id: investmentid }),
      { Authorization: localStorage.getItem("token") }
    );
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      setTableData([]);
      return;
    }
    setStateObj(res.items[0]);
    setcurrency(res.items[0].investor_currency)
  };
  const GetTableData = async () => {
    let res = await ApiRequest(
      "get_profit.php",
      "POST",
      JSON.stringify({ investment_id: investmentid, year }),
      { Authorization: localStorage.getItem("token") }
    );
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
        return;
      }
      SetDefaultTableData();
      return;
    }
    if (res.items[0].profit) {
      // setprofittotal(res.items[0].overall_total_value);
        let overall = 0,
        profitall = 0;
      for (let obj of JSON.parse(res.items[0].profit)) {
        overall += parseFloat(obj.profit_for);
        profitall += parseFloat(obj.profit);
      }
      setoveralltotal(overall);
      setprofittotal(profitall);
      setTableData(JSON.parse(res.items[0].profit));
    } else {
      SetDefaultTableData();
    }
  };
  const SetDefaultTableData = () => {
    let arr = [];
    let overall = 0,
      profitall = 0;
    for (let x of months) {
        let obj = {
            scheme: stateObj.scheme,
            scheme_interest: stateObj.scheme_interest,
            year: year,
            remark: stateObj.remark,
            month: x,
            profit: 0,
            cdate: "",
            investment_id: investmentid,
            profit_date: stateObj.Profit_commencement_date,
            investment_amount: parseFloat(stateObj.investor_amount).toFixed(2),
            profit_for:
              (parseFloat(stateObj.investor_amount) *
                parseFloat(stateObj.scheme_interest)) /
              100,
          };
      arr.push(obj);
      overall += obj.profit_for;
      profitall += obj.profit;
    }
    setTableData(arr);
    setoveralltotal(overall);
    setprofittotal(profitall);
  };
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="card mb-2">
            {/* <div className="card-header">
              <h4 className="card-title text-color-1 mb-0">Search Profit</h4>
            </div> */}
            <div className="card-body">
              <div
                className="d-md-flex mb-3"
                style={{ justifyContent: "space-evenly", alignItems: "end" }}
              >
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label">Inv. ID</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Inv ID"
                        autoComplete="off"
                        // onChange={(e) => {
                        //   setFromDate(e.target.value);
                        // }}
                        value={stateObj.investor_id}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label">Inv. Name</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Inv Name"
                        autoComplete="off"
                        // onChange={(e) => {
                        //   setFromDate(e.target.value);
                        // }}
                        value={stateObj.investor_name}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label">Year</label>
                    <div className="input-group">
                      <select
                        type="year"
                        className="form-control"
                        placeholder="Inv Name"
                        autoComplete="off"
                        onChange={(e) => {
                          setYear(e.target.value);
                        }}
                        value={year}
                      >
                        <option value={""}>-- Select Year --</option>
                        {years.map((item) => {
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label">Currency</label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        autoComplete="off"
                        onChange={(e) => {
                          setcurrency(e.target.value);
                        }}
                        value={currency}
                      >
                        <option value={""}>-- Select Currency --</option>
                        {currencies.map((item,index) => {
                          return (
                            <option key={index} value={item.currency}>
                              {item.currency}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            title={"Profit List"}
            itemsperpage={12}
            hideitemspage={true}
            hidepagenation={true}
            rowbg={true}
            tooltip="remark"
            heads={[
              {
                name: "Month",
                fieldname: "month",
              },
              {
                name: "Amount",
                fieldname: "investment_amount",
                view: (item) => (
                  <label>
                    {item.investment_amount &&
                      parseFloat(item.investment_amount).toFixed(2)}
                  </label>
                ),
              },
              {
                name: "Profit For",
                fieldname: "profit_for",
              },
              {
                name: "Profit",
                fieldname: "profit",
              },
              {
                name: "Date",
                fieldname: "cdate",
                view: (item) => (
                  <label>
                    {item.cdate ? moment(item.cdate).format("DD/MM/YYYY") : "-"}
                  </label>
                ),
              },
            ]}
            data={tableData}
          />
          <div className="row mt-2 col-lg-12">
              {/*<h6>Current Year Profit : {overalltotal}</h6>*/}
              <h6>Profit Paid : {profittotal}</h6>
            </div>
        </div>
      </div>
    </div>
  );
}

export default ViewProfit;
