import React, { useEffect, useState } from "react";
import ApiRequest from "../../Common/restservice";
import { errorToast, successToast } from "../../Common/showtoast";
import { json, useNavigate, useParams } from "react-router-dom";
import { GetCountries } from "react-country-state-city";
import moment from "moment";
function AddPayments() {
  const [schemes, setSchemes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [heads, setHeads] = useState([]);
  const [inputObj, setInputObj] = useState({});
  const [tableData, setTableData] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [exchanges, setExchanges] = useState([]);
  const [investments, setInvestments] = useState([]);
  const navigate = useNavigate();
  const { investorid } = useParams();
  const [stateObj, setStateObj] = useState({
    investmentAmount: "",
    investmentDate: moment().format("YYYY-MM-DD"),
    exchangeRate: "",
    profitDate: moment().format("YYYY-MM-DD"),
    headconvetionamount: "",
    investorcurrency: "",
    currency: "",
    investorname: "",
    schemetype1: "",
    schemetype2: "",
    exchangeRateDate: "",
    period: "",
    compundtype: "",
    compoundperiod: "",
    remarks: "",
    investorindex: "",
    headindex: "",
    headname: "",
    headid: "",
    profit_type: "",
    investorid: "",
  });
  useEffect(() => {
    GetExchanges();
    GetSchemes();
    GetHeads();
    GetInvestors();
    GetCountries().then((res) => {
      if (res.length > 0) {
        setCountries(res);
      }
    });
  }, []);
  useEffect(() => {
    if (schemes.length && investorid && heads.length && investors.length) {
      GetInvestments(inputObj.investor_id);
    }
  }, [schemes.length, investorid, heads.length, investors.length]);
  const GetInvestments = async (_id) => {
    let res = await ApiRequest(
      "user_investment2.php",
      "POST",
      JSON.stringify({ investor_id: _id, from_date: "", to_date: "" }),
      { Authorization: localStorage.getItem("token") }
    );
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      return;
    }
    setInvestments(res.items);
  };
  const GetExchanges = async () => {
    let res = await ApiRequest(
      "exchange_report.php",
      "POST",
      JSON.stringify({ from_date: "", to_date: "" }),
      { Authorization: localStorage.getItem("token") }
    );
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      return;
    }
    setExchanges(res.items);
  };
  const GetSchemes = async () => {
    let res = await ApiRequest(
      "scheme_report.php",
      "POST",
      JSON.stringify({ from_date: "", to_date: "" }),
      { Authorization: localStorage.getItem("token") }
    );
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      return;
    }
    setSchemes(res.items);
  };
  const GetHeads = async () => {
    let res = await ApiRequest(
      "head_report.php",
      "POST",
      JSON.stringify({ from_date: "", to_date: "" }),
      { Authorization: localStorage.getItem("token") }
    );
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      return;
    }
    setHeads(res.items);
  };

  const GetInvestors = async () => {
    let res = await ApiRequest(
      "investor_report.php",
      "POST",
      JSON.stringify({ from_date: "", to_date: "" }),
      { Authorization: localStorage.getItem("token") }
    );
    console.log(res);
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      return;
    }
    setInvestors(res.items.filter((e)=>e.memberid !== localStorage.getItem('username')));
  };
  const Update = async () => {
    if (!stateObj.investmentDate) {
      errorToast("Enter valid investment date");
      return;
    } else if (!stateObj.profitDate) {
      errorToast("Select valid profit date");
      return;
    } else if (!stateObj.investorname) {
      errorToast("Enter valid investor name");
      return;
    } else if (!stateObj.investorcurrency) {
      errorToast("Select valid currency");
      return;
    } else if (!stateObj.investorname) {
      errorToast("Enter valid investorname");
      return;
    } else if (!stateObj.headname) {
      errorToast("Enter valid headname");
      return;
    } else if (!stateObj.investmentAmount) {
      errorToast("Enter valid amount");
      return;
    } else if (!stateObj.exchangeRate) {
      errorToast("Enter valid rate");
      return;
    } else if (!stateObj.headconvetionamount) {
      errorToast("Enter valid convertion amount");
      return;
    } else if (!stateObj.schemetype1) {
      errorToast("Select valid Scheme");
      return;
    }
    let jsoninput = {
      investment_date: stateObj.investmentDate,
      Profit_commencement_date: stateObj.profitDate,
      investor_name: stateObj.investorname,
      investor_currency: stateObj.investorcurrency,
      head_name: stateObj.headname,
      head_id: stateObj.headid,
      investor_id: stateObj.investorid,
      head_currency: stateObj.currency,
      investor_amount: stateObj.investmentAmount,
      exchange_rate: stateObj.exchangeRate,
      head_conversion_amount: stateObj.headconvetionamount,
      scheme: stateObj.schemetype1,
      remark: stateObj.remarks,
      scheme_interest: stateObj.period,
      profit_type: stateObj.profit_type,
      headcountry: countries.find((e) => e.currency === stateObj.currency).name,
      investorcountry: countries.find(
        (e) => e.currency === stateObj.investorcurrency
      ).name,
    };
    if (investorid) {
      jsoninput.id = investorid;
    }
    console.log(jsoninput);
    let res = await ApiRequest(
      investorid ? "edit_investment.php" : "add_investment.php",
      "POST",
      JSON.stringify(jsoninput),
      { Authorization: localStorage.getItem("token") }
    );
    if (res.status === "error") {
      errorToast(res.message);
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      return;
    }
    successToast(res.message);
    navigate("/investmentslist");
  };
  useEffect(() => {
    if (stateObj.currency && stateObj.investorcurrency && exchanges.length) {
      if (stateObj.currency === stateObj.investorcurrency) {
        setStateObj({ ...stateObj, exchangeRate: 1 });
      } else {
        let exchange = exchanges.find(
          (e) =>
            e.from_country_currency === stateObj.investorcurrency &&
            stateObj.currency === e.to_country_currency
        );
        console.log(exchange);
        if (exchange) {
          setStateObj({
            ...stateObj,
            exchangeRate: exchange.actual_exchange_rate,
            exchangeRateDate: exchange.date,
          });
        }
      }
    }
  }, [stateObj.currency, stateObj.investorcurrency, exchanges.length]);
  return (
    <div className="container-xxl flex-grow-1 container-p-y" > 
    
      <div className="row">
        <div className="card overflow-hidden dash1-card border-0 " >
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              let json = {
                investor_id : inputObj.investor_id,
                investment_id : inputObj.investment_id,
                amount : inputObj.amount,
                currency : inputObj.investorcurrency,
                reason : inputObj.reason,
                remark : inputObj.remark
              }
              console.log(json);
              let res = await ApiRequest(
                "add_payment.php",
                "POST",
                JSON.stringify(json),
                { Authorization: localStorage.getItem("token") }
              );
              if (res.status === "error") {
                if (
                  res.message === "Session Expired" ||
                  res.message === "Token expired or invalid"
                ) {
                  localStorage.clear();
                  navigate("/login");
                }
                return;
              }
              successToast(res.message);
              setInputObj({});
              navigate('/paymentlist');
            }}
          >
            
    <h3 className=" mt-3 mb-3">Add Payment</h3>
            <div className="row">
              <div className="row mb-3">
                <div className="col-lg-6 ">
                  <h6 className="mb-1 ml-4">Investor</h6>
                  <div className="input-container mb-2">
                    <div className="input-group">
                      <select
                        className="form-control col-lg-4"
                        onChange={(e) => {
                          setInputObj({
                            ...inputObj,
                            investor_id: e.target.value,
                            investorcurrency: investors.find(
                              (e1) => e1.memberid === e.target.value
                            ).currency,
                          });
                          GetInvestments(e.target.value);
                        }}
                        value={inputObj.investor_id}
                      >
                        <option value={""}>-- Select Investor --</option>
                        {investors.map((item, index) => {
                          return (
                            item.status=="1" ?
                            <option key={index} value={item.memberid}>
                              {item.name}
                            </option> :""
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <h6 className="mb-1 ml-4">Investments</h6>
                  <div className="input-container mb-2">
                    <div className="input-group">
                      <select
                        className="form-control col-lg-4"
                        onChange={(e) => {
                          setInputObj({
                            ...inputObj,
                            investment_id: e.target.value,
                          });
                        }}
                        value={inputObj.investment_id}
                      >
                        <option value={""}>-- Select Investment --</option>
                        {investments.map((item, index) => {
                          return (
                            item.status=="1" ?
                            <option key={index} value={item.investment_id}>
                              {item.investment_id} - {item.investor_amount}{" "}
                              {item.investor_currency}
                            </option> :""
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <h6 className="mb-1 ml-4">Amount</h6>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Amount"
                      onChange={(e) => {
                        let json = {
                          ...inputObj,
                          amount: e.target.value,
                        };
                        setInputObj(json);
                      }}
                      value={inputObj.amount}
                    />
                    <label type="text" className="input-group-text">
                      {inputObj.investorcurrency}
                    </label>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <h6 className="mb-1 ml-4">Reason</h6>
                  <div className="input-container">
                    <textarea
                      type="text"
                      className="form-control col-lg-4"
                      placeholder="Enter reason"
                      onChange={(e) => {
                        setInputObj({
                          ...inputObj,
                          reason: e.target.value,
                        });
                      }}
                      value={inputObj.reason}
                    />
                  </div>
                  <h6 className="mb-1 ml-4">Remarks</h6>
                  <div className="input-container">
                    <textarea
                      type="text"
                      className="form-control col-lg-4"
                      placeholder="Enter remarks"
                      onChange={(e) => {
                        setInputObj({
                          ...inputObj,
                          remark: e.target.value,
                        });
                      }}
                      value={inputObj.remark}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button className="ml-2 mb-5 btn btn-primary" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddPayments;
