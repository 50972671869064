import PerfectScrollbar from "perfect-scrollbar";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function Sidebar() {
  const { pathname } = useLocation();
  const [currentSubmenu, setCurrentSubMenu] = useState(null);
  useEffect(() => {
    new PerfectScrollbar("#menu-inner");
  }, []);
  const closeMenu = () => {
    if (window.innerWidth < 720) {
      document.querySelector("html").classList.remove("layout-menu-expanded");
    }
    setCurrentSubMenu(null);
  };
  return (
    <aside
      onMouseEnter={() => {
        let ele = document.querySelector("html");
        if (
          ele.classList.contains("layout-menu-collapsed") &&
          !ele.classList.contains("layout-menu-expanded")
        ) {
          ele.classList.add("layout-menu-hover");
        }
      }}
      onMouseLeave={() => {
        let ele = document.querySelector("html");
        if (
          ele.classList.contains("layout-menu-collapsed") &&
          !ele.classList.contains("layout-menu-expanded")
        ) {
          ele.classList.remove("layout-menu-hover");
        }
      }}
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme"
    >
      <div className="app-brand demo ">
        <a href="#" className="app-brand-link">
          <span className="app-brand-logo demo">
            {/* <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#00e792" d="M0 21L6 0L13 12L19 0L26 21H0Z" />
                        </svg> */}
            <img
              src={require("../Assets/Images/logo-dark.png")}
              style={{ width: 30, height: 30 }}
            />
          </span>
          <div className="app-brand-text demo menu-text fw-bold">
            <h4 className="mb-0">DEDICO</h4>
            <h4 className="mb-0" style={{ fontSize: 8 }}>
              NETWORK PRIVATE LIMITED
            </h4>
          </div>
        </a>
        <label
          onClick={() => {
            document
              .querySelector("html")
              .classList.toggle("layout-menu-collapsed");
            if (window.innerWidth < 720) {
              document
                .querySelector("html")
                .classList.toggle("layout-menu-expanded");
            }
          }}
          className="layout-menu-toggle menu-link text-large ms-auto"
        >
          <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
          <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
        </label>
      </div>
      <div className="menu-inner-shadow"></div>
      <ul className="menu-inner py-1" id="menu-inner">
        <li className={`menu-item ${pathname === "/home" ? "active" : ""}`}>
          <Link to={"/home"} onClick={closeMenu} className={`menu-link`}>
            <i className="menu-icon tf-icons ti ti-smart-home"></i>
            <div>Dashboard</div>
          </Link>
        </li>
        {localStorage.getItem("permission_status") === "1" && (
          <li className={`menu-item ${currentSubmenu === 2 ? "open" : ""}`}>
            <label
              className={`menu-link menu-toggle`}
              onClick={() => {
                setCurrentSubMenu(currentSubmenu === 2 ? null : 2);
              }}
            >
              <i className="menu-icon tf-icons ti ti-user"></i>
              <div>Sub Investors</div>
            </label>
            <ul class="menu-sub">
              <li class="menu-item">
                <Link
                  onClick={closeMenu}
                  to={"/investorslist"}
                  class="menu-link"
                >
                  <div>Sub Investors List</div>
                </Link>
              </li>
              <li class="menu-item">
                <Link
                  onClick={closeMenu}
                  to={"/addinvestors"}
                  class="menu-link"
                >
                  <div>Add Sub Investor</div>
                </Link>
              </li>
            </ul>
          </li>
        )}
        <li className={`menu-item ${currentSubmenu === 3 ? "open" : ""}`}>
          <label
            className={`menu-link menu-toggle`}
            onClick={() => {
              setCurrentSubMenu(currentSubmenu === 3 ? null : 3);
            }}
          >
            <i className="menu-icon tf-icons ti ti-wallet"></i>
            <div>Investments</div>
          </label>
          <ul class="menu-sub">
            <li class="menu-item">
              <Link
                onClick={closeMenu}
                to={"/investmentslist"}
                class="menu-link"
              >
                <div>Investments List</div>
              </Link>
            </li>
            {localStorage.getItem("permission_status") === "1" && (
              <li class="menu-item">
                <Link
                  onClick={closeMenu}
                  to={"/addinvestments"}
                  class="menu-link"
                >
                  <div>Add Investment</div>
                </Link>
              </li>
            )}
            {localStorage.getItem("permission_status") === "1" && (
              <li class="menu-item">
                <Link
                  onClick={closeMenu}
                  to={"/addsubinvestments"}
                  class="menu-link"
                >
                  <div>Add Sub Investment</div>
                </Link>
              </li>
            )}
          </ul>
        </li>
        <li className={`menu-item`}>
          <Link onClick={closeMenu} to={"/profitlist"} className={`menu-link`}>
            <i className="menu-icon tf-icons ti ti-gift"></i>
            <div>Profit</div>
          </Link>
        </li>
        {localStorage.getItem("permission_status") === "1" && <li className={`menu-item ${currentSubmenu === 9 ? "open" : ""}`}>
          <label
            className={`menu-link menu-toggle`}
            onClick={() => {
              setCurrentSubMenu(currentSubmenu === 9 ? null : 9);
            }}
          >
            <i className="menu-icon tf-icons ti ti-cash"></i>
            <div>Payments</div>
          </label>
          <ul class="menu-sub">
            <li class="menu-item">
              <Link onClick={closeMenu} to={"/addpayment"} class="menu-link">
                <div>Add Payments</div>
              </Link>
            </li>
            <li class="menu-item">
              <Link onClick={closeMenu} to={"/paymentlist"} class="menu-link">
                <div>Payment Lists</div>
              </Link>
            </li>
          </ul>
        </li>}
      </ul>
    </aside>
  );
}

export default Sidebar;
