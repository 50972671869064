export default async function ApiRequest(
  url,
  method,
  body,
  header
) {
  const baseurl = "https://dedicoinvest.ch/inv_api/investor/";//"http://192.168.1.3:9080/investment/investor/";// ; //
  let obj = {
    method: method,
  };
  if (body) {
    obj["body"] = body;
  }
  if (header) {
    obj["headers"] = header;
  }
  try {
    let ApiRequest = await fetch(baseurl + url, obj);
    let res = await ApiRequest.text();
    console.log(res);
    return JSON.parse(res);
  } catch (e) {
    console.log(e);
    return { status: "error", message: e.message };
  }
}
