import React, { useEffect, useState } from 'react'
import ApiRequest from '../../Common/restservice';
import { useNavigate } from 'react-router-dom';
import { errorToast, successToast } from '../../Common/showtoast';
import DataTable from '../../Components/DataTable';
import moment from 'moment';
import Swal from 'sweetalert2';

function Paymentlist() {
    const [tableData, setTableData] = useState([{ id: "INV001", headstatus: "true", investor: "Kani Anna", head: "Dharsan", date: "12/07/2023", country: "Switzerlad" }]);
    const [fromDate, setFromDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().add(1, 'days').format('YYYY-MM-DD'));
    const navigate = useNavigate();
    useEffect(() => {
        GetTeamList();
    }, [])
    const GetTeamList = async () => {
        let jsoninput = {
            from_date: fromDate ? moment(fromDate).format("YYYY/MM/DD") : "",
            to_date: toDate ? moment(toDate).format("YYYY/MM/DD") : ""
        }
        let url = "payment_report.php";
        let res = await ApiRequest(url, "POST", JSON.stringify(jsoninput), { Authorization: localStorage.getItem("token") });
        if (res.status === "error") {
            if (res.message === "Session Expired" || res.message === "Token expired or invalid") {
                localStorage.clear();
                navigate("/login");
            }
            setTableData([]);
            return;
        }
        setTableData(res.items);
    }
    const DeleteorBlockPayment = async (id, isblock) => {
        let url = isblock ? "payment_status.php" : "delete_payment.php";
        let jsoninput = { id };
        if (isblock) {
            jsoninput.status = isblock === "1" ? "0" : "1";
        }
        let res = await ApiRequest(url, "POST", JSON.stringify(jsoninput), { Authorization: localStorage.getItem("token") });
        if (res.status === "error") {
            errorToast(res.message);
            if (res.message === "Session Expired" || res.message === "Token expired or invalid") {
                localStorage.clear();
                navigate("/login");
            }
            setTableData([]);
            return;
        }
        successToast(res.message);
        GetTeamList();
    }
    return (
        <div className='container-xxl flex-grow-1 container-p-y'>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className='card mb-2'>
                        <div className="card-header">
                            <h4 className="card-title text-color-1 mb-0">
                                Search Payment
                            </h4>
                        </div>
                        <div className='card-body'>
                            <div className="d-md-flex mb-3" style={{ justifyContent: "space-evenly", alignItems: 'end' }}>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label className='form-label'>From Date</label>
                                        <div className="input-group">

                                            <input type="date" className="form-control daterange-single" id="start_date" name="start_date" placeholder="From Date" autoComplete="off" onChange={(e) => {
                                                setFromDate(e.target.value);
                                            }} value={fromDate} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label className='form-label'>To Date</label>
                                        <div className="input-group">
                                            <input type="date" className="form-control daterange-single" id="end_date" name="end_date" placeholder="To Date" autoComplete="off" onChange={(e) => {
                                                setToDate(e.target.value);
                                            }} value={toDate} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 text-left">
                                    <div className="form-group"><button type="button" className="btn btn-primary w-100" onClick={(e) => { GetTeamList() }}> Search </button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        title={"Payment List"}
                        heads={[{
                            name: "ID",
                            fieldname: "payment_id"
                        },  {
                            name: "Investor",
                            fieldname: "investor_id"
                        },{
                            name: "Investor Name",
                            fieldname: "investor_name"
                        }, {
                            name: "Investment Id",
                            fieldname: "investment_id"
                        },  {
                            name: "Investment Amount",
                            fieldname: "investor_amount"
                        }, {
                            name: "Date",
                            fieldname: "date",
                            view:(item)=><label>{item.date && moment(item.date).format('DD/MM/YYYY')}</label>
                        }, {
                            name: "Amount",
                            fieldname: "amount"
                        }, {
                            name: "Currency",
                            fieldname: "currency"
                        }, {
                            name: "Reason",
                            fieldname: "reason"
                        }, {
                            name: "Remarks",
                            fieldname: "remark"
                        },{
                            name:"Status",
                            view:(item)=><span title={item.status === "1" ? "Paid" : "Pending"} className={`badge ${item.status !== "1" ? "bg-warning" : "bg-success"}`} style={{ cursor: 'pointer' }} onClick={(e) => {
                               if(item.status === "0")
                                DeleteorBlockPayment(item.id, item.status);
                            }}>{item.status === "1" ? "Paid" : "Pending"}</span>
                        }, 
                        // {
                        //     name: "Task",
                        //     view: (item) => <span className='d-flex' style={{ justifyContent: 'space-evenly' }}>
                        //        {
                        //         item.status === "0" &&  <span className='badge bg-primary' style={{cursor:'pointer'}} onClick={async (e) => {
                        //             let res = await Swal.fire({
                        //                 title:"Delete",
                        //                 text:"Are you sure you want to delete this record?",
                        //                 confirmButtonText:"Yes",
                        //                 cancelButtonText:"No",
                        //                 showCancelButton:true
                        //             })
                        //             if(res.isConfirmed)
                        //                 DeleteorBlockPayment(item.id)
                        //         }}><i className='ti ti-trash' /></span>                                
                        //        }
                        //     </span>
                        // }
                    ]}
                        data={tableData} />
                </div>
            </div>
        </div>
    )
}

export default Paymentlist
