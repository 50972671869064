import { useEffect, useState } from "react";
import CustomPagination from "./PageNation";

export default function DataTable({
  title,
  data = [],
  heads = [],
  currentpage = 1,
  itemsperpage = 10,
  hideitemspage = false,
  hidepagenation = false,
  searchEnabled = true,
  headerRight = "",
  tooltip = ""
}) {
  const [displayItems, setDisplayItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(currentpage);
  const [totalPages, setTotalPages] = useState(1);
  const [sortedItems, setsortedItems] = useState([]);
  const [sortType, setSortType] = useState("asc");
  const [sortfield, setSortfield] = useState("");
  const [searchText, setSearchText] = useState("");
  const [itemsPerPage, setitemsPerPage] = useState(itemsperpage);
  useEffect(() => {
    SortDatas(data);
  }, [sortfield, sortType]);
  useEffect(() => {
    Initiate();
  }, [data, itemsPerPage]);
  const Initiate = () => {
    let total = Math.ceil(data.length / itemsPerPage);
    if (totalPages !== total) setTotalPages(total);
    if (currentPage !== 1) setCurrentPage(1);
    setsortedItems(data);
  };
  const ProcessData = (items) => {
    setDisplayItems(
      items.slice(
        (currentPage - 1) * itemsPerPage,
        (currentPage - 1) * itemsPerPage + itemsPerPage
      )
    );
  };
  useEffect(() => {
    ProcessData(sortedItems);
  }, [currentPage, sortedItems]);
  useEffect(() => {
    if (searchText) {
      let items = data.filter((e) => {
        let str = JSON.stringify(e);
        return str.toLowerCase().includes(searchText.toLowerCase());
      });
      let total = Math.ceil(items.length / itemsPerPage);
      setTotalPages(total);
      SortDatas(items);
    } else {
      let total = Math.ceil(data.length / itemsPerPage);
      setTotalPages(total);
      SortDatas(data);
    }
  }, [searchText]);
  const SortDatas = (_data) => {
    if (sortfield) {
      let newarr = _data.slice();
      if (sortType === "asc") {
        newarr.sort((a, b) => {
          if (
            typeof a[sortfield] === "string" &&
            typeof b[sortfield] === "string"
          ) {
            return a[sortfield].localeCompare(b[sortfield]);
          }
          return a[sortfield] - b[sortfield];
        });
      } else {
        newarr.sort((a, b) => {
          if (
            typeof a[sortfield] === "string" &&
            typeof b[sortfield] === "string"
          ) {
            return b[sortfield].localeCompare(a[sortfield]);
          }
          return b[sortfield] - a[sortfield];
        });
      }
      setsortedItems(newarr);
    } else {
      setsortedItems(_data);
    }
  };
  return (
    <div className="card">
      <div className="card-header d-flex  align-items-center">
        {title && (
          <h3
            className="card-title text-color-1 mb-0"
            style={{ fontWeight: "200", fontSize: "22px" }}
          >
            {title}
          </h3>
        )}
        {headerRight && <h4 className="ms-auto">{headerRight}</h4>}
        {searchEnabled && (
          <div className="d-flex ms-auto align-items-center">
            <input
              className="form-control mx-3"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              placeholder="Search..."
            />
          </div>
        )}
        {!hideitemspage && (
          <div className="d-flex align-items-center">
            <label className="input-label" style={{ whiteSpace: "nowrap" }}>
              Items Per Page :{" "}
            </label>
            <select
              className="form-control"
              style={{ marginLeft: 10 }}
              value={itemsPerPage}
              onChange={(e) => {
                setitemsPerPage(parseInt(e.target.value));
              }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        )}
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table
            className="table table-vcenter text-nowrap mb-0 table-striped table-bordered border-top"
            style={{ textAlign: "center" }}
          >
            <thead className="">
              <tr>
                {heads.map((item, index) => {
                  return (
                    <th key={item.name + index}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <label>{item.name}</label>
                        {item.fieldname && (
                          <div
                            style={{
                              flexDirection: "column",
                              display: "flex",
                              marginLeft: 10,
                            }}
                          >
                            <i
                              onClick={() => {
                                if (
                                  sortfield === item.fieldname &&
                                  sortType === "asc"
                                ) {
                                  setSortfield("");
                                } else {
                                  setSortType("asc");
                                  setSortfield(item.fieldname);
                                }
                              }}
                              className={`fas fa-${
                                sortType === "asc" &&
                                sortfield === item.fieldname
                                  ? "caret-up"
                                  : "angle-up"
                              }`}
                              style={{ lineHeight: 0.7 }}
                            />
                            <i
                              onClick={() => {
                                if (
                                  sortfield === item.fieldname &&
                                  sortType === "desc"
                                ) {
                                  setSortfield("");
                                } else {
                                  setSortType("desc");
                                  setSortfield(item.fieldname);
                                }
                              }}
                              className={`fas fa-${
                                sortType === "desc" &&
                                sortfield === item.fieldname
                                  ? "caret-down"
                                  : "angle-down"
                              }`}
                              style={{ lineHeight: 0.7 }}
                            />
                          </div>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {displayItems.length > 0 ? (
                displayItems.map((item, index) => {
                  let json = {};
                  // if (searchText) {
                  //     json = JSON.stringify(item)
                  //     json = JSON.parse(json.replace(new RegExp(searchText, 'gi'), (match) => `<b>${match}</b>`));
                  // }
                  if (searchText) {
                    Object.entries(item).forEach(([key, value]) => {
                      json[key] = value
                        .toString()
                        .replace(
                          new RegExp(searchText, "gi"),
                          (match) => `<b>${match}</b>`
                        );
                    });
                  } else {
                    json = item;
                  }
                  return (
                    <tr key={index} title={tooltip ? json[tooltip] : ""}>
                      {heads.map((col, colindex) =>
                        col.view ? (
                          <td>
                            <col.view {...item} />
                          </td>
                        ) : (
                          <td
                            key={colindex}
                            dangerouslySetInnerHTML={{
                              __html: json[col.fieldname],
                            }}
                          />
                        )
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-center" colSpan={heads.length}>
                    No Records Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {!hidepagenation && data.length > 0 && (
          <div className="card-footer">
            <CustomPagination
              className="justify-content-center mb-0 mt-2"
              onPageChange={(val) => {
                setCurrentPage(val);
              }}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>
    </div>
  );
}
