import Footer from "./Components/Footer";
import Sidebar from "./Components/Sidebar";
import Header from "./Components/Header";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Directsponsor from "./Pages/DirectSponsorList";
import Changepassword from "./Pages/Changepassword";
import InvestmentsList from "./Pages/Investments/InvestmentsList";
import AddInvestments from "./Pages/Investments/AddInvestments";
import InvestorsList from "./Pages/Investors/InvestorsList";
import AddInvestors from "./Pages/Investors/AddInvestors";
import AddProfit from "./Pages/Profiter/AddProfit";
import Profitlist from "./Pages/Profiter/ProfitList";
import InvestorsInvestmentslist from "./Pages/Profiter/InvestorsInvestments";
import ViewProfit from "./Pages/Profiter/ViewProfit";
import ViewStatement from "./Pages/Statements/ViewStatements";
import AddPayments from "./Pages/Payment/addpayment";
import Paymentlist from "./Pages/Payment/PaymentList";
import AddSubInvestments from "./Pages/Investments/AddSubInvestments";

function App() {
  // useEffect(()=>{
  //   new PerfectScrollbar("#wrapper")
  // },[])
  return (
    <div className="layout-wrapper layout-content-navbar  ">
      <div className="layout-container">
        <Sidebar />
        <div className="layout-page" id="wrapper">
          <Header />
          <div className="content-wrapper">
            <Routes>
              <Route path="/home" element={<Dashboard />} />
              <Route path="/directsponsor" element={<Directsponsor />} />
              <Route path="/settings" element={<Changepassword />} />
              <Route path="/investorslist" element={<InvestorsList />} />
              <Route path="/addinvestors" element={<AddInvestors />} />
              <Route
                path="/editinvestor/:investorid"
                element={<AddInvestors />}
              />
              <Route path="/investmentslist" element={<InvestmentsList />} />
              <Route path="/addinvestments" element={<AddInvestments />} />
              <Route path="/addsubinvestments" element={<AddSubInvestments />} />
              <Route path="/editsubinvestments/:investorid" element={<AddSubInvestments />} />              
              <Route
                path="/editinvestment/:investorid"
                element={<AddInvestments />}
              />

              <Route
                path="/viewprofit/:investmentid"
                element={<ViewProfit />}
              />
              <Route path="/addprofit/:investmentid" element={<AddProfit />} />
              <Route path="/editprofit/:investorid" element={<AddProfit />} />
              <Route path="/profitlist" element={<Profitlist />} />
              <Route
                path="/investorsinvestments/:investorid"
                element={<InvestorsInvestmentslist />}
              />
              <Route path="/viewstatement/:investmentid" element={<ViewStatement />} />
              <Route path="/addpayment" element={<AddPayments />} />
              <Route path="/editpayment/:paymentid" element={<AddPayments />} />
              <Route path="/paymentlist" element={<Paymentlist />} />
            </Routes>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
      <div
        className="layout-overlay layout-menu-toggle"
        onClick={() => {
          document
            .querySelector("html")
            .classList.remove("layout-menu-collapsed");
          if (window.innerWidth < 720) {
            document
              .querySelector("html")
              .classList.remove("layout-menu-expanded");
          }
        }}
      ></div>
      <div className="drag-target"></div>
    </div>
  );
}

export default App;
