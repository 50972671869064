import { toast } from "react-toastify";
function successToast(message, duration = 3000) {
    toast.success(message, {
        position: "top-right",
        autoClose: duration
    })
}
function errorToast(message, duration = 3000) {
    toast.error(message, {
        position: "top-right",
        autoClose: duration
    })
}
export function copyToClipboard(str) {
    navigator.clipboard.writeText(str);
    successToast("Copied", 2000);
}

export {
    successToast, errorToast
}