import React, { useEffect, useState } from 'react'
import ApiRequest from '../../Common/restservice';
import { useNavigate } from 'react-router-dom';
import { errorToast } from '../../Common/showtoast';
import DataTable from '../../Components/DataTable';
import { toast } from 'react-toastify';
import moment from 'moment';
import { GetCountries } from 'react-country-state-city';
import Swal from 'sweetalert2';

function InvestmentsList() {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        GetTeamList();
    }, [])
    const GetTeamList = async () => {
        let jsoninput = {
            from_date: fromDate ? moment(fromDate).format("YYYY/MM/DD") : "",
            to_date: toDate ? moment(toDate).format("YYYY/MM/DD") : ""
        }
        let url = "investment_report.php";
        let res = await ApiRequest(url, "POST", JSON.stringify(jsoninput), { Authorization: localStorage.getItem("token") });
        if (res.status === "error") {
            if (res.message === "Session Expired" || res.message === "Token expired or invalid") {
                localStorage.clear();
                navigate("/login");
            }
            setTableData([]);
            return;
        }
        let currencies = await GetCountries();
        console.log(res.items);
        setTableData(res.items.map((e)=>{
            let symbol = currencies.find((e1)=>e1.currency === e.investor_currency);
            e.symbol = symbol?.currency_symbol;
            return e;
        }));
    }
    const DeleteorBlockInvestment = async (id, isblock) => {
        let url = isblock ? "investment_status.php" : "delete_investment.php";
        let jsoninput = { id };
        if (isblock) {
            jsoninput.status = isblock === "1" ? "0" : "1";
        }
        let res = await ApiRequest(url, "POST", JSON.stringify(jsoninput), { Authorization: localStorage.getItem("token") });
        if (res.status === "error") {
            errorToast(res.message);
            if (res.message === "Session Expired" || res.message === "Token expired or invalid") {
                localStorage.clear();
                navigate("/login");
            }
            setTableData([]);
            return;
        }
        GetTeamList();
    }
    return (
        <div className='container-xxl flex-grow-1 container-p-y'>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className='card mb-2'>
                        <div className="card-header">
                            <h4 className="card-title text-color-1 mb-0">
                                Search Investments
                            </h4>
                        </div>
                        <div className='card-body'>
                            <div className="d-md-flex mb-3" style={{ justifyContent: "space-evenly", alignItems: 'end' }}>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label className='form-label'>From Date</label>
                                        <div className="input-group">

                                            <input type="date" className="form-control daterange-single" id="start_date" name="start_date" placeholder="From Date" autoComplete="off" onChange={(e) => {
                                                setFromDate(e.target.value);
                                            }} value={fromDate} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label className='form-label'>To Date</label>
                                        <div className="input-group">
                                            <input type="date" className="form-control daterange-single" id="end_date" name="end_date" placeholder="To Date" autoComplete="off" onChange={(e) => {
                                                setToDate(e.target.value);
                                            }} value={toDate} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 text-left">
                                    <div className="form-group"><button type="button" className="btn btn-success w-100" onClick={(e) => { GetTeamList() }}> Search </button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        title={"Investments"}
                        heads={[{
                            name: "Document",
                            fieldname: "investment_id",
                            view: (item) => <h6 className='mb-1 text-success'>{item.investment_id}</h6>
                        }, {
                            name: "Invest Date",
                            fieldname: "investment_date",
                            view: (item) => <label>{item.investment_date ? moment(item.investment_date).format("DD/MM/YYYY") : ""}</label>
                        }, 
                        {
                            name: "Profit Date",
                            fieldname: "Profit_commencement_date",
                            view: (item) => <label>{item.Profit_commencement_date ? moment(item.Profit_commencement_date).format("DD/MM/YYYY") : ""}</label>
                        }, 
                        {
                            name: "Investor Name",
                            fieldname: "investor_name",
                            view: (item) => <label>{item.investor_name}/{item.investor_currency}</label>
                        }, {
                            name: "Investor Amount",
                            fieldname: "investor_amount",
                            view: (item) => <label>{item.symbol} {item.investor_amount && parseFloat(item.investor_amount).toFixed(2)}</label>
                        }, 
                        {
                          name: "Head Investor",
                          fieldname: "head_investor_name"
                        },
                        // {
                        //   name: "Head Investor",
                        //   fieldname: "head_investor_name"
                        // },
                        // {
                        //     name: "Head",
                        //     fieldname: "head_name",
                        //     view: (item) => <label>{item.head_name} / {item.head_currency}</label>
                        // }, {
                        //     name: "Ex Rate",
                        //     fieldname: "exchange_rate",
                        //     view: (item) => <label>{item.exchange_rate && parseFloat(item.exchange_rate).toFixed(2)} / {item.head_currency}</label>
                        // }, {
                        //     name: "Convertion Amount",
                        //     fieldname: "head_conversion_amount",
                        //     view: (item) => <label>{item.head_conversion_amount && parseFloat(item.head_conversion_amount).toFixed(2)} / {item.head_currency}</label>
                        // }, 
                        {
                            name: "Scheme",
                            fieldname: "scheme"
                        }, 
                        // {
                        //     name: "Profit Type",
                        //     fieldname: "profit_type"
                        // }, 
                        {
                            name: "Interest Rate",
                            fieldname: "scheme_interest"
                        }, {
                            name:"Status",
                            view:(item)=><span title={item.status === "1" ? "Block" : "Unblock"} className={`badge ${item.status !== "1" ? "bg-warning" : "bg-success"}`} > 
                            {item.status === "0"
                            ? "Pending"
                            : item.status === "1"
                            ? "Active"
                            : "Closed"}</span>
                        }, 
                        // {
                        //     name: "Task",
                        //     view: (item) => <span className='d-flex' style={{ justifyContent: 'space-evenly' }}>
                        //         <span className='badge bg-info' style={{ cursor: 'pointer' }} onClick={(e) => {
                        //             navigate('/editinvestment/' + item.id);
                        //         }}><i className='ti ti-pencil' /></span>
                        //         <span className='badge bg-danger' style={{ cursor: 'pointer' }} onClick={async (e) => {
                        //             let res = await Swal.fire({
                        //                 title:"Delete",
                        //                 text:"Are you sure you want to delete this record?",
                        //                 confirmButtonText:"Yes",
                        //                 cancelButtonText:"No",
                        //                 showCancelButton:true
                        //             })
                        //             if(res.isConfirmed)
                        //                 DeleteorBlockInvestment(item.id)
                        //         }}><i className='ti ti-trash' /></span>
                                
                        //     </span>
                        // }
                    ]}
                        data={tableData} />
                </div>
            </div>
        </div>
    )
}

export default InvestmentsList
