import React, { useEffect, useState } from "react";
import ApiRequest from "../../Common/restservice";
import { errorToast, successToast } from "../../Common/showtoast";
import { json, useNavigate, useParams } from "react-router-dom";
import { GetCountries } from "react-country-state-city";
import moment from "moment";
function AddSubInvestments() {
  const [schemes, setSchemes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [heads, setHeads] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [exchanges, setExchanges] = useState([]);
  const navigate = useNavigate();
  const { investorid } = useParams();
  const [stateObj, setStateObj] = useState({
    investmentAmount: "",
    investmentDate: moment().format("YYYY-MM-DD"),
    exchangeRate: "",
    profitDate: moment().format("YYYY-MM-DD"),
    headconvetionamount: "",
    investorcurrency: "",
    currency: "",
    investorname: "",
    schemetype1: "",
    schemetype2: "",
    exchangeRateDate: "",
    period: "",
    compundtype: "",
    compoundperiod: "",
    remarks: "",
    investorindex: "",
    headindex: "",
    headname: "",
    headid: "",
    profit_type: "",
    investorid: "",
  });
  useEffect(() => {
    GetExchanges();
    GetSchemes();
    GetHeads();
    GetInvestors();
    GetCountries().then((res) => {
      if (res.length > 0) {
        setCountries(res);
      }
    });
  }, []);
  useEffect(() => {
    if (schemes.length && investorid && heads.length && investors.length) {
      GetInvestments();
    }
  }, [schemes.length, investorid, heads.length, investors.length]);
  const GetInvestments = async () => {
    let res = await ApiRequest(
      "get_investment.php",
      "POST",
      JSON.stringify({ id: investorid }),
      { Authorization: localStorage.getItem("token") }
    );
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        // navigate("/login");
      }
      return;
    }
    let item = res.items[0];
    let from = heads.findIndex(
      (e) => e.name.toLowerCase() === item.head_name.toLowerCase()
    );
    let to = investors.findIndex(
      (e) => e.name.toLowerCase() === item.investor_name.toLowerCase()
    );
    console.log(from);
    console.log(to);
    setStateObj({
      investmentDate: item.investment_date
        ? moment(item.investment_date).format("YYYY-MM-DD")
        : "",
      profitDate: item.Profit_commencement_date
        ? moment(item.Profit_commencement_date).format("YYYY-MM-DD")
        : "",
      investorname: item.investor_name,
      investorcurrency: item.investor_currency,
      headname: item.head_name,
      headid: item.head_id,
      investorid: item.investor_id,
      headindex: from,
      investorindex: to,
      currency: item.head_currency,
      investmentAmount: item.investor_amount,
      exchangeRate: item.exchange_rate,
      headconvetionamount: item.head_conversion_amount,
      schemetype1: item.scheme,
      remarks: item.remark,
      period: item.scheme_interest,
      profit_type: item.profit_type,
    });
  };
  const GetExchanges = async () => {
    let res = await ApiRequest(
      "exchange_report.php",
      "POST",
      JSON.stringify({ from_date: "", to_date: "" }),
      { Authorization: localStorage.getItem("token") }
    );
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      return;
    }
    setExchanges(res.items);
  };
  const GetSchemes = async () => {
    let res = await ApiRequest(
      "scheme_report.php",
      "POST",
      JSON.stringify({ from_date: "", to_date: "" }),
      { Authorization: localStorage.getItem("token") }
    );
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      return;
    }
    setSchemes(res.items);
  };
  const GetHeads = async () => {
    let res = await ApiRequest(
      "head_report.php",
      "POST",
      JSON.stringify({ from_date: "", to_date: "" }),
      { Authorization: localStorage.getItem("token") }
    );
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      return;
    }
    setHeads(res.items);
  };
  const GetInvestors = async () => {
    let res = await ApiRequest(
      "investor_report.php",
      "POST",
      JSON.stringify({ from_date: "", to_date: "" }),
      { Authorization: localStorage.getItem("token") }
    );
    console.log(res);
    if (res.status === "error") {
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      return;
    }
    let items = res.items.filter(
      (e) => e.memberid !== localStorage.getItem("username")
    );
    let itemindex = res.items.findIndex(
      (e) => e.memberid === localStorage.getItem("username")
    );
    if (itemindex !== -1) {
      setStateObj({
        ...stateObj,
        headid: res.items[itemindex].memberid,
        headname: res.items[itemindex].name,
        currency: res.items[itemindex].currency,
        headindex: itemindex,
      });
    }
    setInvestors(items);
  };
  const Update = async () => {
    if (!stateObj.investmentDate) {
      errorToast("Enter valid investment date");
      return;
    } else if (!stateObj.profitDate) {
      errorToast("Select valid profit date");
      return;
    } else if (!stateObj.investorname) {
      errorToast("Enter valid investor name");
      return;
    } else if (!stateObj.investorcurrency) {
      errorToast("Select valid currency");
      return;
    } else if (!stateObj.investorname) {
      errorToast("Enter valid investorname");
      return;
    } else if (!stateObj.headname) {
      errorToast("Enter valid headname");
      return;
    } else if (!stateObj.investmentAmount) {
      errorToast("Enter valid amount");
      return;
    } else if (!stateObj.exchangeRate) {
      errorToast("Enter valid rate");
      return;
    } else if (!stateObj.headconvetionamount) {
      errorToast("Enter valid convertion amount");
      return;
    } else if (!stateObj.schemetype1) {
      errorToast("Select valid Scheme");
      return;
    }
    let jsoninput = {
      investment_date: stateObj.investmentDate,
      Profit_commencement_date: stateObj.profitDate,
      investor_name: stateObj.investorname,
      investor_currency: stateObj.investorcurrency,
      head_name: "",
      head_id: "",
      investor_id: stateObj.investorid,
      head_currency: "",
      investor_amount: stateObj.investmentAmount,
      exchange_rate: stateObj.exchangeRate,
      head_conversion_amount: stateObj.headconvetionamount,
      scheme: stateObj.schemetype1,
      remark: stateObj.remarks,
      scheme_interest: stateObj.period,
      profit_type: stateObj.profit_type,
      headcountry: "",
      investorcountry: countries.find(
        (e) => e.currency === stateObj.investorcurrency
      ).name,
    };
    if (investorid) {
      jsoninput.id = investorid;
    }
    console.log(jsoninput);
    let res = await ApiRequest(
      investorid ? "edit_investment.php" : "add_investment.php",
      "POST",
      JSON.stringify(jsoninput),
      { Authorization: localStorage.getItem("token") }
    );
    if (res.status === "error") {
      errorToast(res.message);
      if (
        res.message === "Session Expired" ||
        res.message === "Token expired or invalid"
      ) {
        localStorage.clear();
        navigate("/login");
      }
      return;
    }
    successToast(res.message);
    navigate("/investmentslist");
  };
  useEffect(() => {
    if (stateObj.currency && stateObj.investorcurrency && exchanges.length) {
      if (stateObj.currency === stateObj.investorcurrency) {
        setStateObj({ ...stateObj, exchangeRate: 1 });
      } else {
        let exchange = exchanges.find(
          (e) =>
            e.from_country_currency === stateObj.investorcurrency &&
            stateObj.currency === e.to_country_currency
        );
        console.log(exchange);
        if (exchange) {
          setStateObj({
            ...stateObj,
            exchangeRate: exchange.actual_exchange_rate,
            exchangeRateDate: exchange.date,
          });
        }
      }
    }
  }, [stateObj.currency, stateObj.investorcurrency, exchanges.length]);
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="card overflow-hidden dash1-card border-0 ">
          <form
            className="card-body "
            onSubmit={(e) => {
              e.preventDefault();
              Update();
            }}
          >
            <h3 className=" mt-3 mb-3">Add Investments</h3>
            <div className="row">
              <div className="col-lg-6 row mb-3">
                <h6 className="mb-1 ml-4">Investment Date</h6>
                <div className="input-container mb-2">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Date"
                    onChange={(e) => {
                      setStateObj({
                        ...stateObj,
                        investmentDate: e.target.value,
                      });
                    }}
                    value={stateObj.investmentDate}
                  />
                </div>
                {/* <h6 className="mb-1 ml-4">Profit Commencement Date</h6>
                <div className="input-container mb-2">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Date"
                    onChange={(e) => {
                      setStateObj({
                        ...stateObj,
                        profitDate: e.target.value,
                      });
                    }}
                    value={stateObj.profitDate}
                  />
                </div> */}
                <h6 className="mb-1 ml-4">Sub Investor Name</h6>
                <div className="input-group mb-2">
                  <select
                    type="text"
                    className="form-control col-lg-4"
                    placeholder="Sub Investor name"
                    onChange={(e) => {
                      setStateObj({
                        ...stateObj,
                        investorid: investors[e.target.value].memberid,
                        investorname: investors[e.target.value].name,
                        investorcurrency: investors[e.target.value].currency,
                        investorindex: e.target.value,
                      });
                    }}
                    value={stateObj.investorindex}
                  >
                    <option value={""}>-- Select Investor --</option>
                    {investors.map((item, index) => {
                      return (
                        item.status=="1" ?
                        <option key={index} value={index}>
                          {item.name}
                        </option> :""
                      );
                    })}
                  </select>
                  <select
                    className="form-control"
                    placeholder="Investor Currecy"
                    onChange={(e) => {
                      setStateObj({
                        ...stateObj,
                        investorcurrency: e.target.value,
                      });
                    }}
                    value={stateObj.investorcurrency}
                  >
                    <option value={""}>-- Select Currency --</option>
                    {countries.map((item, index) => {
                      return (
                        <option
                          value={item.currency}
                          key={item.currency + index}
                        >
                          {item.currency}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <h6 className="mb-1 ml-4">Investor Name</h6>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    className="form-control col-lg-4"
                    placeholder="Head name"
                    // onChange={(e) => {
                    //   setStateObj({
                    //     ...stateObj,
                    //     headname: heads[e.target.value].name,
                    //     currency: heads[e.target.value].currency,
                    //     headid: heads[e.target.value].head_id,
                    //     headindex: e.target.value,
                    //   });
                    // }}
                    readOnly
                    value={stateObj.headname}
                  />
                  {/* <option value={""}>-- Select Head --</option>
                    {heads.map((item, index) => {
                      return (
                        <option key={index} value={index}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select> */}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Head Currency"
                    readOnly
                    value={stateObj.currency}
                  />
                </div>
                
              </div>
              <div className="col-lg-6 row mb-3">
              <h6 className="mb-1 ml-4">Investment Amount</h6>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Investment amount"
                    onChange={(e) => {
                      let conv = 0;
                      if (stateObj.exchangeRate && e.target.value) {
                        conv =
                          parseFloat(stateObj.exchangeRate) *
                          parseFloat(e.target.value);
                      }
                      let json = {
                        ...stateObj,
                        investmentAmount: e.target.value,
                      };
                      if (conv > 0) {
                        json.headconvetionamount = conv;
                      }
                      setStateObj(json);
                    }}
                    value={stateObj.investmentAmount}
                  />
                  <label type="text" className="input-group-text">
                    {stateObj.investorcurrency}
                  </label>
                </div>
                {/* <h6 className="mb-1 ml-4 fs-12" style={{ fontSize: 12 }}>
                  Exchange Rate {stateObj.currency && "to "}{" "}
                  {stateObj.currency && (
                    <span className="text-success">{stateObj.currency}</span>
                  )}{" "}
                  {stateObj.exchangeRateDate && (
                    <label style={{ fontSize: 12 }}>
                      Last Exchange Updated Date :{" "}
                      <span style={{ color: "red" }}>
                        {stateObj.exchangeRateDate}
                      </span>
                    </label>
                  )}{" "}
                </h6>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Currency"
                    onChange={(e) => {
                      let conv = 0;
                      let lessconv = 0;
                      if (stateObj.investmentAmount && e.target.value) {
                        conv =
                          parseFloat(stateObj.investmentAmount) *
                          parseFloat(e.target.value);
                      } else if (
                        stateObj.headconvetionamount &&
                        e.target.value
                      ) {
                        lessconv =
                          parseFloat(stateObj.headconvetionamount) /
                          parseFloat(e.target.value);
                      }
                      let json = {
                        ...stateObj,
                        exchangeRate: e.target.value,
                      };
                      if (conv > 0) {
                        json.headconvetionamount = conv;
                      }
                      if (lessconv > 0) {
                        json.investmentAmount = lessconv;
                      }
                      setStateObj(json);
                      // setStateObj({
                      //   ...stateObj,
                      //   exchangeRate: e.target.value,
                      // });
                    }}
                    value={stateObj.exchangeRate}
                  />
                  <label type="text" className="input-group-text">
                    {stateObj.currency}
                  </label>
                </div> */}
                {/* <h6 className="mb-1 ml-4">Head Convertion Amount</h6>
                <div className="input-container mb-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Convertion amount"
                    onChange={(e) => {
                      let conv = 0;
                      if (stateObj.exchangeRate && e.target.value) {
                        conv =
                          parseFloat(e.target.value) /
                          parseFloat(stateObj.exchangeRate);
                      }
                      let json = {
                        ...stateObj,
                        headconvetionamount: e.target.value,
                      };
                      if (conv > 0) {
                        json.investmentAmount = conv;
                      }
                      setStateObj(json);
                    }}
                    value={stateObj.headconvetionamount}
                  />
                </div> */}
                <div className="row">
                  <h6 className="mb-1 ml-4">Scheme</h6>
                  <div className="input-group mb-2">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        let json = {
                          ...stateObj,
                          schemetype1: e.target.value,
                        };
                        if (e.target.value) {
                          let items = schemes.find(
                            (e1) => e1.scheme_name === e.target.value
                          );
                          if (items) {
                            json.period = items.interest_rate;
                          }
                        }
                        setStateObj(json);
                      }}
                      value={stateObj.schemetype1}
                    >
                      <option value={""}>-- Select Scheme --</option>
                      {schemes.map((item, index) => {
                        return (
                          item.status=="1" ?
                          <option key={index} value={item.scheme_name}>
                            {item.scheme_name}
                          </option> :""
                        );
                      })}
                    </select>
                  </div>
                </div>
                {/* <div className="col-lg-6 row">
                  <h6 className="mb-1 ml-4">Percentage</h6>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Percentage"
                      onChange={(e) => {
                        setStateObj({
                          ...stateObj,
                          period: e.target.value,
                        });
                      }}
                      value={stateObj.period}
                    />
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={"%"}
                    />
                  </div>
                </div> */}
                {/* <h6 className="mb-1 ml-4">Profit Type</h6>
                <div className="input-container mb-2">
                  <select
                    type="text"
                    className="form-control"
                    placeholder="Profit Type"
                    onChange={(e) => {
                      setStateObj({ ...stateObj, profit_type: e.target.value });
                    }}
                    value={stateObj.profit_type}
                  >
                    <option value={""}> -- Select Profit Type -- </option>
                    <option value={"Normal"}>Normal</option>
                    <option value={"Fixed"}>Fixed</option>
                  </select>
                </div> */}
                <h6 className="mb-1 ml-4">Remarks</h6>
                <div className="input-container">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Remarks..."
                    onChange={(e) => {
                      setStateObj({
                        ...stateObj,
                        remarks: e.target.value,
                      });
                    }}
                    value={stateObj.remarks}
                  />
                </div>
              </div>

              {/* <div className="col-lg-6 row mb-3">
                <div
                  className={`${
                    stateObj.compundtype === "Compound"
                      ? "col-lg-6"
                      : "col-lg-12"
                  } row`}
                >
                  <h6 className="mb-1 ml-4">Compund Type</h6>
                  <div className="input-container">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setStateObj({
                          ...stateObj,
                          compundtype: e.target.value,
                        });
                      }}
                      value={stateObj.compundtype}
                    >
                      <option value={""}>-- Select Compound Type --</option>
                      <option value={"Compound"}>Compound</option>
                      <option value={"Un-Compound"}>Un-Compound</option>
                    </select>
                  </div>
                </div>
                {stateObj.compundtype === "Compound" && (
                  <div className="col-lg-6 row">
                    <h6 className="mb-1 ml-4">Compound Period</h6>
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control col-lg-4"
                        placeholder="Compound period"
                        onChange={(e) => {
                          setStateObj({
                            ...stateObj,
                            compoundperiod: e.target.value,
                          });
                        }}
                        value={stateObj.compoundperiod}
                      />
                    </div>
                  </div>
                )}
              </div> */}
            </div>
            <button className="ml-2 mb-5 btn btn-success" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddSubInvestments;
